<template>
  <div :class="{footer: true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <div class="nav-inner-wp">
      <div class="left-wp">
        <div class="logo-wp">
          <svg-icon icon-class="logo_white" />
        </div>
      </div>
      <div class="middle-wp">
        <div class="nav-items-wp">
          <div class="nav-item-wp" @click="toLink(1)">
            <span class="text">{{$t('header.about')}}</span>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('header.strategies')}}</a>
          </div>
          <div class="nav-item-wp" @click="toLink(2)">
            <span class="text">{{$t('strategies.infrastructure.descList.text2')}}</span>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('footer.text1')}}</a>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('footer.text2')}}</a>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('header.media')}}</a>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('footer.text3')}}</a>
          </div>
          <div class="nav-item-wp">
            <a class="text" href="#">{{$t('footer.text4')}}</a>
          </div>
        </div>
      </div>
      <div class="right-wp">
        <back-to-top class="back-top-top"></back-to-top>
      </div>
    </div>
    <div class="copyright-wp">
      <div class="left-wp">
        <div class="ins-wp">
          <svg-icon icon-class="ins" />
        </div>
      </div>
      <div class="right-wp">
        <div class="copyright-content-wp">
          <span class="text">{{$t('footer.copyright')}}</span>
          <span class="text">{{$t('footer.copyrightDesc')}}</span>
        </div>
      </div>
    </div>
    <div class="sep-h"></div>
    <div class="nav-outer-wp">
      <div class="left-wp">
        <div class="column-1-wp">
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text5')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text6')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text7')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text8')}}</a>
          </div>
        </div>
        <div class="column-2-wp">
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text9')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text10')}}</a>
          </div>
        </div>
      </div>
      <div class="sep-v"></div>
      <div class="left-wp">
        <div class="column-1-wp">
          <div class="item-wp" @click="toLink(11)">
            <span class="text">{{$t('footer.text11')}}</span>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text12')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text13')}}</a>
          </div>
          <div class="item-wp">
            <a class="text" href="#">{{$t('footer.text14')}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackToTop from "@/components/backToTop"
import {mapGetters} from "vuex";

export default {
  name: "FooterIndex",
  components: {
    BackToTop
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ])
  },
  methods: {
    toLink(type) {
      switch (type) {
        case 1:
          this.$router.push({
            path: '/'
          })
          break;
        case 2:
          this.$router.push({
            path: '/'
          })
          break;
        case 11:
          this.$router.push({
            path: '/media/contacts'
          })
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background: #202024;

  .nav-inner-wp {
    padding: 5.625rem 6.25rem 0 6.25rem;
    display: flex;

    .left-wp {
      width: 27.875rem;
      flex-shrink: 0;

      .logo-wp {
        .svg-icon {
          width: 6.0625rem;
          height: 2.25rem;
        }
      }
    }

    .middle-wp {
      flex-shrink: 1;

      .nav-items-wp {
        width: 40rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .nav-item-wp {
          margin-bottom: 1.875rem;
          width: 20rem;
          cursor: pointer;

          .text {
            font-size: 1.125rem;
            font-weight: 300;
            color: #FFFFFF;
            font-family: PingFang SC;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }

    .right-wp {
      flex-grow: 1;

      .back-top-top {
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  .copyright-wp {
    margin-top: 8.125rem;
    padding: 0 6.25rem;
    display: flex;

    .left-wp {
      width: 27.875rem;

      .ins-wp {
        .svg-icon {
          width: 4.625rem;
          height: 4.625rem;
        }
      }
    }

    .right-wp {
      .copyright-content-wp {
        width: 46.8125rem;

        .text {
          font-size: 1rem;
          font-weight: 300;
          color: #959595;
          font-family: PingFang SC;
          line-height: 1.1;
          display: block;
        }
      }
    }
  }

  .sep-h {
    margin-top: 1.1875rem;
    border-top: 1px solid #545457;
  }

  .nav-outer-wp {
    display: flex;

    .left-wp {
      width: 50%;
      padding-top: 1.25rem;
      padding-left: 8.625rem;
      padding-bottom: 5.125rem;
      display: flex;

      .column-1-wp {
        width: 50%;

        .item-wp {
          margin-top: 1.875rem;
          cursor: pointer;

          .text {
            font-size: 1rem;
            font-weight: 300;
            color: #959595;
            font-family: PingFang SC;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .column-2-wp {
        width: 50%;
        margin-left: 2.25rem;

        .item-wp {
          margin-top: 1.875rem;
          cursor: pointer;

          .text {
            font-size: 1rem;
            font-weight: 300;
            color: #959595;
            font-family: PingFang SC;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }

    .sep-v {
      border-left: 1px solid #545457;
    }

    .right-wp {
      width: 50%;
      padding-top: 1.25rem;
      padding-bottom: 5.125rem;

    }
  }
}

.is-pad {
  $mul: 1;

  .nav-inner-wp {
    .middle-wp {
      .nav-items-wp {
        .nav-item-wp {
          .text {
            font-size: 1.125rem * $mul;
          }
        }
      }
    }
  }

  .copyright-wp {
    .right-wp {
      .copyright-content-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }
    }
  }

  .nav-outer-wp {
    .left-wp {
      .column-1-wp {
        .item-wp {
          .text {
            font-size: 1rem * $mul;
          }
        }
      }
      .column-2-wp {
        .item-wp {
          .text {
            font-size: 1rem * $mul;
          }
        }
      }
    }
  }
}

.is-mobile {
  $mul: 1;

  .nav-inner-wp {
    .left-wp {
      width: 30%;
    }

    .middle-wp {
      width: 40%;

      .nav-items-wp {
        width: 100%;

        .nav-item-wp {
          width: 25rem;

          .text {
            font-size: 1.5rem * $mul;
            display: block;
          }
        }
      }
    }
  }

  .copyright-wp {
    .left-wp {
      width: 30%;
    }

    .right-wp {
      width: 70%;

      .copyright-content-wp {
        width: 100%;

        .text {
          font-size: 1.25rem * $mul;
          display: block;
        }
      }
    }
  }

  .nav-outer-wp {
    .left-wp {
      flex-direction: column;

      .column-1-wp {
        width: 100%;

        .item-wp {
          .text {
            font-size: 1.5rem * $mul;
            display: block;
          }
        }
      }
      .column-2-wp {
        margin-left: 0;
        width: 100%;

        .item-wp {
          .text {
            font-size: 1.5rem * $mul;
            display: block;
          }
        }
      }
    }
  }
}
</style>
