import {createWebHashHistory, createRouter } from 'vue-router'

import IndexPage from '@/pages/IndexPage'
import StrategiesAsia from '@/pages/strategies/Asia'
import StrategiesOpportunities from '@/pages/strategies/Opportunities'
import StrategiesGrowth from '@/pages/strategies/Growth'
import StrategiesSecondaries from '@/pages/strategies/Secondaries'
import StrategiesInfrastructure from '@/pages/strategies/Infrastructure'
import MediaContacts from '@/pages/media/Contacts'
import MediaEmailAlert from '@/pages/media/EmailAlert'

const routes = [
    { path: '/', component: IndexPage, meta: {title: 'Our People | CVC'}},
    { path: '/strategies/asia', component: StrategiesAsia, meta: {title: 'Asia | CVC'}},
    { path: '/strategies/opportunities', component: StrategiesOpportunities, meta: {title: 'Strategic Opportunities | CVC'}},
    { path: '/strategies/growth', component: StrategiesGrowth, meta: {title: 'Growth | CVC'}},
    { path: '/strategies/secondaries', component: StrategiesSecondaries, meta: {title: 'Secondaries | CVC'}},
    { path: '/strategies/infrastructure', component: StrategiesInfrastructure, meta: {title: 'Infrastructure | CVC'}},
    { path: '/media/contacts', component: MediaContacts, meta: {title: 'Media Contacts | CVC'}},
    { path: '/media/email_alert', component: MediaEmailAlert, meta: {title: 'Email Alerts | CVC'}},
]

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0}
        }
    },
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router;
