export default {
    common: {
        'scrollToTop': 'Scroll to top'
    },
    header: {
        about: 'About',
        login: 'Login',
        search: 'Search',
        strategies: 'Strategies',
        media: 'Media',
        strategiesSubNavDesc: 'CVC has seven complementary strategies across private equity, secondaries, credit and infrastructure through which we invest on behalf of pension funds and other leading institutions.',
        findOutMore: 'Find out More',
        strategiesNavLabel1: 'Asia',
        strategiesNavLabel2: 'Strategic Opportunities',
        strategiesNavLabel3: 'Growth',
        strategiesNavLabel4: 'Secondaries',
        strategiesNavLabel5: 'Infrastructure',
        mediaSubNavDesc: 'The latest news and insights from around the CVC network',
        mediaNavLabel1: 'Media Contacts',
        mediaNavLabel2: 'Email Alerts',
        inviteCode: 'Invite Code',
    },
    footer: {
        copyright: 'Copyright © 2024 CVC Capital Partners plc',
        copyrightDesc: 'All figures as of 30 June 2024, and adjusted to reflect the acquisition of DIF Capital Partners on 1 July 2024, unless otherwise indicated. Excluding DIF active investments which are as of 31 March 2024.',
        text1: 'Sustainability',
        text2: 'Portfolio',
        text3: 'Shareholders',
        text4: 'Sign up to alerts',
        text5: 'Terms of Use',
        text6: 'Cookie Policy',
        text7: 'Privacy Policy',
        text8: 'Accessibility',
        text9: 'Modern Slavery Statement',
        text10: 'CVC Regulatory Disclosures',
        text11: 'Contact',
        text12: 'Report Fraud',
        text13: 'Sitemap',
        text14: 'Cookie Consent',
    },
    index: {
        ourPeople: 'Our People',
        ourPeopleDesc: 'Led by a deep and established senior leadership, our entrepreneurial teams are the foundation of CVC’s success',
        position1: 'Partner',
        position2: 'Investment Strategy Director',
        platform: 'Platform',
        position: 'Position',
        office: 'Office',
        people1: {
            desc1: 'Mitra partner, bergabung dengan CVC pada tahun 2013.  Andi adalah kepala kantor CVC di Indonesia dan berdomisili di Singapura.',
            desc2: 'Sebelum bergabung dengan CVC, ia menjabat sebagai Presiden direktur dan Kepala Perbankan Investasi PT Morgan Stanley Asia Indonesia selama empat tahun.',
            desc3: 'Andi memiliki gelar Sarjana Teknik Industri dari Oregon State University dan gelar Magister Manajemen dari University of Texas, Amerika Serikat.',
            platform: 'Private Equity',
            office: 'Singapore'
        },
        people2: {
            desc1: 'Saat ini menjabat sebagai direktur strategi investasi di CVC, dengan fokus pada penelitian pembangunan ekonomi dan kebijakan keuangan.',
            desc2: 'Selama bertahun-tahun, beliau berkomitmen melakukan riset saham di pasar Indonesia dan Asia Tenggara, melakukan analisis mendalam terhadap tren pasar dan membantu investor merumuskan strategi investasi yang ditargetkan.',
            office: 'Indonesia'
        },
        people3: {
            desc1: 'Mitra kamiyang bergabung dengan CVC pada tahun 2018.',
            desc2: 'Budi adalah kepala operasi CVC di Indonesia dan berbasis di Jakarta. Fokus pada peningkatan kinerja pasar perusahaan dan pelaksanaan strategi investasi.',
            desc3: 'Dengan pengetahuan industri yang mendalam dan keterampilan kepemimpinan yang sangat baik, dia secara aktif berpartisipasi dalam proyek investasi penting perusahaan dan berkomitmen untuk membantu pelanggan mencapai pertumbuhan kekayaan.',
            office: 'Jakarta'
        },
        people4: {
            desc1: 'Saat ini menjabat sebagai Direktur Strategi Investasi di Institute of Development Economics and Finance (INDEF), yang utamanya bergerak dalam penelitian pembangunan ekonomi dan kebijakan keuangan.',
            desc2: 'Sebelumnya, beliau menjabat sebagai manajer portofolio di tim ekuitas CVC Indonesia selama enam tahun dan memiliki banyak pengalaman industri, dengan lebih dari 27 tahun pengalaman di bidang investasi.',
            desc3: 'Profesor Dimas pandai merumuskan kerangka makro dan menetapkan ide-ide taktis yang fleksibel atas dasar ini untuk membantu investor menangkap peluang di lingkungan pasar yang kompleks.',
        },
        people5: {
            desc1: 'Profesor Adi Sutanto bergabung dengan CVC pada tahun 2018 sebagai Kepala Investasi, fokus pada investasi ekuitas swasta di Asia dan Eropa. Ia lulusan Universitas Negeri Indonesia dengan gelar sarjana di bidang keuangan dan memiliki kualifikasi Chartered Financial Analyst (CFA). Dengan 20 tahun pengalaman dalam analisis keuangan, ia mengkhususkan diri dalam riset pasar dan strategi investasi.',
            desc2: 'Sebelum bergabung dengan CVC, Adi menjabat sebagai Kepala Pasar Investasi di BCA Sekuritas, memimpin berbagai proyek investasi. Ia berkomitmen untuk memberikan saran investasi yang tepat, membantu perusahaan mencapai tujuan keuangan di pasar yang dinamis, dan menciptakan nilai jangka panjang. ',
            desc3: 'Karakteristik Perdagangan: Fokus pada investasi nilai, menyukai perusahaan berkualitas dengan valuasi rendah, dan ahli dalam menggali saham di bawah nilai valuasi. Pemikiran Investasi: Memperhatikan trend pasar untuk mengidentifikasi peluang perdagangan yang dipicu oleh reaksi berlebihan, serta terus memperbarui metode analisis dan strategi investasi seiring dengan dinamika pasar dan perubahan kebijakan.',
        }
    },
    strategies: {
        asia: {
            desc: 'CVC has one of the largest and longest-established pan-regional office networks of any private equity business in Asia and has been active in the region since 1999',
            stat1Text: 'Assets under management',
            stat2Text: 'Active investments',
            stat3Text: 'Investment professionals',
            descList: {
                text1: 'CVC’s Asia private equity strategy is focused on control, co-control and structured minority investments in high quality businesses in core consumer and services sectors across Asia. Typical enterprise values are between $250 million and $1.5 billion.',
                text2: 'High-quality businesses in key growth sectors',
                text3: 'CVC invests in businesses operating in domestic demand-driven industries in both mature and developing countries that are:',
                text4: 'Core sectors benefiting from the local rising middle class, growing consumption and other demographic and secular trends in Asia, such as consumer and consumer services, retail, financial services, healthcare, TMT and business services',
                text5: 'Established businesses with superior market positions, a good track record and solid reputations',
                text6: 'billion',
                text7: 'Funds committed',
                text8: 'More about CVC Asia',
                text9: 'Asia’s largest pan-regional network',
                text10: 'CVC Asia’s investment professionals comprise a deep bench of senior leaders with an extensive local knowledge and a strong track record of leading successful private equity investments in Asia.',
                text11: 'Case studies',
                text12: 'Creating sustainable value',
                text13: 'CVC’s ability to bring to bear the full extent of its global resources on any situation gives it a competitive advantage when creating value during CVC’s ownership period.',
                text14: 'See more',
                text15: 'PT Softox',
                text16: 'Supporting a business poised to deliver growth',
                text17: 'Read article',
                text18: 'Matahari Department Stores',
                text19: 'Accelerating growth',
                text20: 'SHOWING',
            }
        },
        opportunities: {
            title: 'Strategic',
            title1: 'Opportunities',
            desc: 'CVC established its Strategic Opportunities strategy in 2014 to invest in high-quality businesses that may not suit a traditional private equity mandate',
            descList: {
                text1: 'CVC’s Strategic Opportunities team works in close partnership with CVC’s country and sector teams to identify investment opportunities.',
                text2: 'The strategy has a core focus on corporate private equity investments with a lower risk profile, primarily in Europe and North America, as well as the opportunity to partner with founding families or foundations looking for a long-term partner.',
                text3: 'The enterprise values of the companies in which the Strategic Opportunities strategy invests are typically between €1 billion and €5 billion and more, with a longer holding period of around six to 15 years.',
                text4: 'High-quality, stable businesses with longer holding periods',
                text5: 'CVC Strategic Opportunities invests in control, co-control or minority influence opportunities in businesses that have the following qualities:',
                text6: 'High quality, cash generative and stable businesses and/or assets with safe capital structures',
                text7: 'Operating in low-volatility sectors and environments',
                text8: 'Providing essential goods or services to non-cyclical sectors',
                text9: 'Offering longer-term opportunities for strategic development',
                text10: 'More about CVC Strategic Opportunities',
                text11: 'Committed to the longer term',
                text12: 'CVC’s Strategic Opportunities team offer longer-term capital to founding families or partners of high-quality business in low-volatility sectors.',
            }
        },
        growth: {
            desc: 'CVC’s Growth strategy was launched in 2014 to target the large volume of potential opportunities in high-growth technology-related companies',
            descList: {
                text1: 'CVC Growth targets middle-market, high-growth companies operating in the software and technology-enabled business services sectors and, in particular, businesses where technology is used as a means to provide mission-critical services to customers. It primarily targets North American and European investments, making equity investments in excess of $50 million.',
                text2: 'High-growth technology-related companies',
                text3: 'Through its proactive, thematically driven sourcing effort, CVC Growth invests in businesses with characteristics including:',
                text4: 'Strong growth rates',
                text5: 'Recurring revenue model',
                text6: 'Large total addressable market',
                text7: 'Strong customer diversification and/or customer segmentation',
                text8: 'Attractive unit economics',
                text9: 'More about CVC Growth',
                text10: 'High growth, high conviction',
                text11: 'With its proactive ‘Map and Attack’ strategy, CVC Growth targets high-growth technology and tech-enabled business services investments.',
                text12: 'Creating sustainable value',
                text13: 'CVC’s ability to bring to bear the full extent of its global resources on any situation gives it a competitive advantage when creating value during CVC’s ownership period.',
                text14: 'Kount',
                text15: 'A rewarding partnership focused on innovation',
                text16: 'Wireless Logic',
                text17: 'Rapid expansion into new territories and markets',
            }
        },
        secondaries: {
            desc: 'CVC Secondary Partners, established through a strategic partnership in 2022 with Glendower, focuses on secondary markets globally',
            descList: {
                text1: 'Headquartered in London with an office in New York, CVC Secondary Partners (formerly known as Glendower) has a team of 38 investment professionals and is led by a senior team that has worked together in secondaries for nearly 20 years. The firm manages $13 billion in aggregate capital commitments across multiple funds backed by leading institutional and private investors worldwide.',
                text2: 'The firm became part of CVC when a strategic partnership was formed in 2022, with the final acquisition completed in 2024.',
                text3: 'Visit CVC Secondary Partners website',
                text4: 'CVC Secondary Partners is a mid-sized secondaries manager, focusing on buyout and cash flow generative assets with potential for near-term liquidity. It has an established track record having closed over 190 transactions involving more than 1,500 fund interests since inception. The team pursues a distinctive two-pronged strategy underpinned by a disciplined investment playbook, targeting LP Portfolio Secondaries and GP-led Secondaries to capitalise on the secondary market evolution.',
                text5: 'Fund portfolio secondaries',
                text6: 'CVC Secondary Partners has operational scale to systematically underwrite $5 million to $1 billion funds’ portfolios and single fund positions, providing comprehensive solutions for tail-end portfolios, complex private feeder portfolios, and difficult-to-value assets.',
                text7: 'Tail-end portfolios, requiring operational capabilities to transact on older dated, highly fragmented portfolios',
                text8: 'Single LP interests with differentiated views and manager access',
                text9: 'Portfolios with significant public exposure requiring access to investment banking platforms',
                text10: 'GP-led secondaries',
                text11: 'With a reputation as a reliable partner of choice for fund managers and investors due to the firm’s long-standing experience in executing complex transactions. CVC Secondary Partner underwrites GP-led transactions ranging from $100 million to $500 million, including assets sales, fund recapitalizations, strip sales, and spinouts.',
                text12: 'Highly experienced, having actively invested in GP-led secondaries since 2007, completing over 60 deals for $3.4 billion',
                text13: 'Reputation as lead underwriter of transactions with mid-market managers',
                text14: 'Expertise in acquiring difficult to value assets using proprietary pricing and structuring capabilities to underwrite challenging transactions across adjacent asset classes',
            }
        },
        infrastructure: {
            desc: 'In 2024, CVC acquired a majority stake in leading infrastructure manager, DIF Capital Partners to create CVC DIF.',
            descList: {
                text1: 'AUM',
                text2: 'People',
                text3: 'Founded in 2005, CVC DIF (formerly DIF Capital Partners) is an infrastructure fund manager with a leading position in managing mid-market investments, primarily in Europe and North America.',
                text4: 'It has two strategies:',
                text5: 'DIF Infrastructure funds that invest in companies and projects that build, own and operate essential infrastructure in core and core+ markets. Typically with longer term contract cover, offering downside protection and yield, combined with the opportunity for additional longer-term value creation.',
                text6: 'DIF Value Add funds that invest in companies with strong competitive positions, often combined with attractive “buy and build”, offering significant growth potential.',
                text7: 'Based across 12 offices, CVC DIF offers a unique market approach combining global presence with the benefits of strong local networks and investment capabilities.',
                text8: 'Visit CVC DIF website',
            }
        }
    },
    media: {
        contacts: {
        },
        emailAlerts: {
            text1: 'Stay up-to-date with the latest CVC news.',
            text2: 'Select to receive alerts for our news and/or regulatory announcements, emailed directly to your inbox.',
            text3: 'News',
            text4: 'Regulatory Announcements',
            text5: 'Select to receive alerts for our news and/or regulatory announcements, emailed directly to your inbox.',
            text6: 'You can unsubscribe via the email alerts that you receive.',
            text7: 'First Name',
            text8: 'Surname',
            text9: 'Your email address',
            text10: 'Please select at least 1 area of interest',
            text11: 'All',
            text12: 'I confirm I',
            text13: 'have read and agree to the terms and conditions',
            text14: 'and consent to having my information stored by CVC',
            text15: 'Recaptcha',
            text16: 'Submit form',
            text17: 'Please register below for Regulatory News announcements.',
            text18: 'Select from the options below to receive news and other financial information by email.',
            text19: 'Email',
            text20: 'Last Name',
            text21: 'Company',
            text22: 'Country',
            text23: 'Subscribe',
            text24: 'Do I have a subscription?',
            text25: 'This site is protected by reCAPTCHA and the Google',
            text26: 'Privacy Policy',
            text27: 'and',
            text28: 'Terms of Service',
            text29: 'apply',
            text30: 'Supplied by',
            text31: 'Terms of Service | Privacy Policy | Cookie Policy',
            text32: 'Stock Exchange Releases',
        }
    }
}
