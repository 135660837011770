import {createI18n} from 'vue-i18n'
import en from './locales/en'
import indonesian from './locales/indonesian'

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    messages: {
        'en': en,
        'indonesian': indonesian
    }
})

export default i18n
