<template>
  <div :class="{'page': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <header-index :no-background="true" theme="white"></header-index>
    <div class="banner-wp">
      <img src="@/assets/images/secondaries.png" class="pic-banner" />
      <div class="title-wp">
        <div class="title-inner-wp">
          <div class="label-wp">
            <span class="text">{{$t('header.strategiesNavLabel4')}}</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.secondaries.desc')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-wp">
      <div class="items-wp">
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="plot_bar" />
          </div>
          <div class="label-wp">
            <span class="text">€15bn</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat1Text')}}</span>
          </div>
        </div>
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="investment" />
          </div>
          <div class="label-wp">
            <span class="text">190</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat2Text')}}</span>
          </div>
        </div>
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="users" />
          </div>
          <div class="label-wp">
            <span class="text">30</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat3Text')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-wp">
      <p class="text">
        {{$t('strategies.secondaries.descList.text1')}}
      </p>
      <p class="text">
        {{$t('strategies.secondaries.descList.text2')}}
      </p>
      <div class="btn-wp">
        <div class="btn">
          <span class="text">{{$t('strategies.secondaries.descList.text3')}}</span>
        </div>
      </div>
      <div class="svg-wp">
        <img src="@/assets/images/edgar-castrejon-se5cwioubze-unsplash.png" class="svg-icon" />
        <div class="fund-committed-wp">
          <div class="num-wp">
            <span class="text">€14</span>
          </div>
          <div class="unit-wp">
            <span class="text">{{$t('strategies.asia.descList.text6')}}</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.descList.text7')}}</span>
          </div>
        </div>
      </div>
      <p class="text more">
        {{$t('strategies.secondaries.descList.text4')}}
      </p>
      <p class="text bold">
        {{$t('strategies.secondaries.descList.text5')}}
      </p>
      <p class="text">
        {{$t('strategies.secondaries.descList.text6')}}
      </p>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text7')}}
        </p>
      </div>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text8')}}
        </p>
      </div>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text9')}}
        </p>
      </div>
      <p class="text bold">
        {{$t('strategies.secondaries.descList.text10')}}
      </p>
      <p class="text">
        {{$t('strategies.secondaries.descList.text11')}}
      </p>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text12')}}
        </p>
      </div>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text13')}}
        </p>
      </div>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.secondaries.descList.text14')}}
        </p>
      </div>
    </div>
    <footer-index></footer-index>
  </div>
</template>

<script>
import HeaderIndex from "@/components/header";
import FooterIndex from "@/components/footer";
import SvgIcon from "@/components/svg/SvgIcon";
import {mapGetters} from "vuex";

export default {
  name: 'StrategiesAsia',
  components: {
    SvgIcon,
    HeaderIndex,
    FooterIndex,
  },
  props: {},
  data: function () {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  unmounted() {
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ])
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.page {
  .banner-wp {
    position: relative;

    .pic-banner {
      width: 100%;
      display: block;
      position: relative;
    }

    .title-wp {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;

      .title-inner-wp {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
        flex-direction: column;

        .label-wp {
          .text {
            font-size: 5.625rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
          }
        }

        .text-wp {
          margin-top: 4rem;
          max-width: 80%;

          .text {
            font-size: 1.375rem;
            font-weight: 200;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
            text-align: center;
            display: block;
          }
        }
      }
    }
  }

  .stat-wp {
    padding: 7.3125rem 11.6875rem;
    box-sizing: border-box;

    .items-wp {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-wp {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0.625rem 6.1875rem 1px rgba(201,202,203,0.4);
        width: 30rem;
        height: 31.5rem;
        justify-content: center;
        border-radius: 1.5rem;

        .icon-wp {
          .svg-icon {
            width: 8.75rem;
            height: 8.75rem;
            display: block;
          }
        }

        .label-wp {
          margin-top: 1.25rem;

          .text {
            font-size: 4.25rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #0A4F88;
          }
        }

        .text-wp {
          margin-top: 2rem;

          .text {
            font-size: 1.25rem;
            font-weight: 600;
            font-family: PingFang SC;
            color: #000000;
          }
        }
      }
    }
  }

  .desc-wp {
    margin-top: 1.6875rem;
    padding: 0 12.0625rem 5.25rem 12.0625rem;
    box-sizing: border-box;

    .text {
      font-size: 1.5rem;
      line-height: 2.5rem;
      font-weight: 300;
      font-family: PingFang SC;
      letter-spacing: 0.1rem;
      color: #000000;
    }

    .bold {
      font-size: 1.625rem;
      font-weight: bold;
      font-family: Source Han Serif SC;
      color: #0A4F88;
    }

    .text-wp {
      display: flex;

      .dot {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background: #0A4F88;
        margin-right: 2rem;
        flex-shrink: 0;
        margin-top: 0.875rem;
      }

      .text {
        margin-top: 0;
      }
    }

    .btn-wp {
      margin-top: 2.875rem;
      margin-bottom: 4.375rem;

      .btn {
        border: 1px solid #009174;
        width: fit-content;
        padding: 1.25rem 3.5625rem;
        box-sizing: border-box;
        border-radius: 6.25rem;

        .text {
          font-size: 1.3125rem;
          font-weight: 600;
          font-family: PingFang SC;
          color: #0A4F88;
          line-height: 1;
        }
      }
    }

    .svg-wp {
      width: 100%;
      margin-top: 1.5rem;
      position: relative;

      .svg-icon {
        width: 100%;
        height: 100%;
        display: block;
      }

      .fund-committed-wp {
        position: absolute;
        right: 6.3125rem;
        bottom: -11.25rem;
        width: 32.75rem;
        height: 27.1875rem;
        border-radius: 1.5rem;
        box-shadow: 0px 0.625rem 6.1875rem 1px rgba(201,202,203,0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #FFFFFF;

        .num-wp {
          .text {
            font-size: 4.75rem;
            color: #0A4F88;
            font-weight: bold;
            font-family: Source Han Serif SC;
          }
        }

        .unit-wp {
          margin-top: 3rem;

          .text {
            font-size: 4.75rem;
            color: #0A4F88;
            font-weight: bold;
            font-family: Source Han Serif SC;
          }
        }

        .text-wp {
          margin-top: 3.625rem;

          .text {
            font-size: 1.5rem;
            color: #0A4F88;
            font-weight: 300;
            font-family: PingFang SC;
          }
        }
      }
    }

    .more {
      margin-top: 19.4375rem;
    }

    .largest {
      margin-top: 3.25rem;
      font-weight: 600;
      font-size: 3.125rem;
    }

    .ln {
      width: 47.9375rem;
    }
  }
}

.is-pad {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .stat-wp {
    .items-wp {
      .item-wp {
        .label-wp {
          .text {
            font-size: 4.25rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.25rem * $mul;
          }
        }
      }
    }
  }

  .desc-wp {
    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .btn-wp {
      .btn {
        .text {
          font-size: 1.3125rem * $mul;
        }
      }
    }

    .svg-wp {
      .fund-committed-wp {
        .num-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .unit-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }

    .largest {
      font-size: 3.125rem * $mul;
    }
  }
}

.is-mobile {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .stat-wp {
    padding-left: 6.25rem;
    padding-right: 6.25rem;

    .items-wp {
      flex-direction: column;

      .item-wp {
        margin-top: 3.75rem;
        width: 100%;

        .label-wp {
          .text {
            font-size: 4.25rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.25rem * $mul;
          }
        }
      }
    }
  }

  .desc-wp {
    padding-left: 6.25rem;
    padding-right: 6.25rem;

    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .btn-wp {
      .btn {
        .text {
          font-size: 1.3125rem * $mul;
        }
      }
    }

    .svg-wp {
      .fund-committed-wp {
        height: auto;
        width: 70%;
        right: 50%;
        margin-right: -35%;
        bottom: -7.5rem;

        .num-wp {
          margin-top: 1rem;

          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .unit-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }

    .largest {
      font-size: 3.125rem * $mul;
    }

    .more {
      margin-top: 10rem;
    }
  }
}
</style>
<style lang="scss">
</style>
