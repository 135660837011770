<template>
  <div :class="{'page': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <header-index :no-background="true" theme="white"></header-index>
    <div class="banner-wp">
      <img src="@/assets/images/media-contacts.png" class="pic-banner" />
      <div class="title-wp">
        <div class="title-inner-wp">
          <div class="label-wp">
            <span class="text">{{$t('header.mediaNavLabel1')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-wp">
      <div class="info-wp">
        <div class="country-wp" v-for="(info, idx) in contactList" :key="idx">
<!--          <span class="country">{{info.country}}</span>-->
          <div class="user-wp" v-for="(user, uIdx) in info.users" :key="`user_` + uIdx">
            <span class="company">{{user.company}}</span>
            <span class="position" v-if="user.position">{{user.position}}</span>
            <span class="name">{{user.name}}</span>
            <span class="tel">{{user.tel}}</span>
            <span class="email">{{user.email}}</span>
          </div>
        </div>
      </div>
    </div>
    <footer-index></footer-index>
  </div>
</template>

<script>
import HeaderIndex from "@/components/header";
import FooterIndex from "@/components/footer";
import {mapGetters} from "vuex";

export default {
  name: 'MediaContacts',
  components: {
    HeaderIndex,
    FooterIndex,
  },
  props: {},
  data: function () {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  unmounted() {
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ])
  },
  methods: {
  }
}
</script>
<script setup>
import {useI18n} from 'vue-i18n'
import {computed, watch, ref} from "vue";
const I18n = useI18n();

const lang = computed(() =>
  I18n.locale.value
)

const contactListEn = [
  // {
  //   country: 'Corporate',
  //   users: [
  //     {
  //       company: 'Patrick Humphris',
  //       position: 'Managing Director, Head of Corporate Affairs',
  //       name: 'CVC',
  //       tel: 'Tel: +44 204 576 9526',
  //       email: 'phumphris@cvc.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Global',
  //   users: [
  //     {
  //       company: 'Husten Huwendiek',
  //       position: 'Managing Director, Global Head of Marketing and Communications',
  //       name: 'CVC',
  //       tel: 'Tel: +44 207 420 4240',
  //       email: 'chuwendiek@cvc.com'
  //     },
  //     {
  //       company: 'Board Nick',
  //       position: 'Director, Marketing & Communications',
  //       name: 'CVC',
  //       tel: 'Tel: +44 207 420 9162',
  //       email: 'nboard@cvc.com'
  //     },
  //   ]
  // },
  // {
  //   country: 'Australia',
  //   users: [
  //     {
  //       company: "Magnus' Citadel",
  //       position: 'Peter Brookes',
  //       name: 'CVC',
  //       tel: '+61 2 8234 0100',
  //       email: 'pbrookes@citadelmagnus.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'China, Hong Kong & SE Asia',
  //   users: [
  //     {
  //       company: 'Brunswick Group Ltd',
  //       position: '',
  //       name: 'Sunitha Chalam',
  //       tel: 'Tel: +852 3512 5000',
  //       email: 'cvcasia@brunswickgroup.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'France',
  //   users: [
  //     {
  //       company: 'Havas',
  //       position: '',
  //       name: 'Sarah Duparc',
  //       tel: 'Tel: +33 6 46 72 39 99',
  //       email: 'sarah.duparc@havas.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Germany',
  //   users: [
  //     {
  //       company: 'Global FGS',
  //       position: '',
  //       name: 'Janna Creuzberg',
  //       tel: 'Tel: +49 (69) 921874-632',
  //       email: 'cvcgermany@fgsglobal.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Italy',
  //   users: [
  //     {
  //       company: 'Brunswick',
  //       position: '',
  //       name: 'Alessandro Iozzia',
  //       tel: 'Tel: +39 02 9288 6200',
  //       email: 'cvcitaly@brunswickgroup.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'India',
  //   users: [
  //     {
  //       company: 'Adfactors PR',
  //       position: '',
  //       name: 'Delna Irani',
  //       tel: '+91 22 6757 4444',
  //       email: 'cvc@adfactorspr.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Japan',
  //   users: [
  //     {
  //       company: 'Ashton',
  //       position: '',
  //       name: 'Colin Young',
  //       tel: 'Tel: +81 3 5425 7220',
  //       email: 'cvcjapanpr@ashton.jp'
  //     }
  //   ]
  // },
  // {
  //   country: 'Korea',
  //   users: [
  //     {
  //       company: 'Comms integrity',
  //       position: '',
  //       name: 'Sarah Song',
  //       tel: 'Tel: +82 2736 1333',
  //       email: 'sarah@integracomms.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Spain',
  //   users: [
  //     {
  //       company: 'Grupo Albion',
  //       position: '',
  //       name: 'Alejandra Moore Mayorga',
  //       tel: 'Tel: +34 915 312 988',
  //       email: 'amoore@groupalbion.net'
  //     }
  //   ]
  // },
  // {
  //   country: 'UK',
  //   users: [
  //     {
  //       company: 'The Maitland CAI ai',
  //       position: '',
  //       name: 'David Sturken',
  //       tel: 'Tel: +44 207 379 5151',
  //       email: 'cvc-maitland@maitland.co.uk'
  //     }
  //   ]
  // },
  {
    country: 'US',
    users: [
      // {
      //   company: 'Stanton Public Relations & Marketing',
      //   position: '',
      //   name: 'Tom Faust',
      //   tel: 'Tel: +1 646 502 3513',
      //   email: 'cvc@stantonprm.com'
      // },
      {
        company: '',
        position: 'CVC partner',
        name: 'Budi Wijaya',
        tel: '',
        email: 'CVBudiWijaya@outlook.com'
      },
      {
        company: '',
        position: 'Investment manager',
        name: 'Putri Maharani',
        tel: '',
        email: 'CVCPutriMaharani@outlook.com'
      },
      {
        company: '',
        position: 'Independent director',
        name: 'Dimas Pramudito',
        tel: '',
        email: 'CVCDimasPramudito@outlook.com'
      },
      {
        company: '',
        position: 'Investment adviser',
        name: 'Maya Suma',
        tel: '',
        email: 'CVCMayaSuma@outlook.com'
      },
      {
        company: '',
        position: 'CVC partner',
        name: 'Adi Sutanto',
        tel: '',
        email: 'CVCAdiSutanto@outlook.com'
      },
      {
        company: '',
        position: 'Investment Manager',
        name: 'Lidia Mutia',
        tel: '',
        email: 'CVCLidiaMutia@outlook.com'
      },
      {
        company: '',
        position: 'Investment manager',
        name: 'Agus Santoso',
        tel: '',
        email: 'CVCAgusSantoso@outlook.com'
      },
      {
        company: '',
        position: 'Investment adviser',
        name: 'Sari Dewi',
        tel: '',
        email: 'CVCSariDewi@outlook.com'
      }
    ]
  },
];
const contactListIndonesian = [
  // {
  //   country: 'Corporate',
  //   users: [
  //     {
  //       company: 'Patrick Humphris',
  //       position: 'Managing Director, Head of Corporate Affairs',
  //       name: 'CVC',
  //       tel: 'Tel: +44 204 576 9526',
  //       email: 'phumphris@cvc.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Global',
  //   users: [
  //     {
  //       company: 'Carsten Huwendiek',
  //       position: 'Managing Director, Global Head of Marketing and Communications',
  //       name: 'CVC',
  //       tel: 'Tel: +44 207 420 4240',
  //       email: 'chuwendiek@cvc.com'
  //     },
  //     {
  //       company: 'Nick Board',
  //       position: 'Director, Marketing & Communications',
  //       name: 'CVC',
  //       tel: 'Tel: +44 207 420 9162',
  //       email: 'nboard@cvc.com'
  //     },
  //   ]
  // },
  // {
  //   country: 'Australia',
  //   users: [
  //     {
  //       company: 'Citadel Magnus',
  //       position: 'Peter Brookes',
  //       name: 'CVC',
  //       tel: '+61 2 8234 0100',
  //       email: 'pbrookes@citadelmagnus.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'China, Hong Kong & SE Asia',
  //   users: [
  //     {
  //       company: 'Brunswick Group Ltd',
  //       position: '',
  //       name: 'Sunitha Chalam',
  //       tel: 'Tel: +852 3512 5000',
  //       email: 'cvcasia@brunswickgroup.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'France',
  //   users: [
  //     {
  //       company: 'Havas',
  //       position: '',
  //       name: 'Sarah Duparc',
  //       tel: 'Tel: +33 6 46 72 39 99',
  //       email: 'sarah.duparc@havas.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Germany',
  //   users: [
  //     {
  //       company: 'FGS Global',
  //       position: '',
  //       name: 'Janna Creuzberg',
  //       tel: 'Tel: +49 (69) 921874-632',
  //       email: 'cvcgermany@fgsglobal.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Italy',
  //   users: [
  //     {
  //       company: 'Brunswick',
  //       position: '',
  //       name: 'Alessandro Iozzia',
  //       tel: 'Tel: +39 02 9288 6200',
  //       email: 'cvcitaly@brunswickgroup.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'India',
  //   users: [
  //     {
  //       company: 'Adfactors PR',
  //       position: '',
  //       name: 'Delna Irani',
  //       tel: '+91 22 6757 4444',
  //       email: 'cvc@adfactorspr.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Japan',
  //   users: [
  //     {
  //       company: 'Ashton',
  //       position: '',
  //       name: 'Colin Young',
  //       tel: 'Tel: +81 3 5425 7220',
  //       email: 'cvcjapanpr@ashton.jp'
  //     }
  //   ]
  // },
  // {
  //   country: 'Korea',
  //   users: [
  //     {
  //       company: 'Integra Comms',
  //       position: '',
  //       name: 'Sarah Song',
  //       tel: 'Tel: +82 2736 1333',
  //       email: 'sarah@integracomms.com'
  //     }
  //   ]
  // },
  // {
  //   country: 'Spain',
  //   users: [
  //     {
  //       company: 'Grupo Albion',
  //       position: '',
  //       name: 'Alejandra Moore Mayorga',
  //       tel: 'Tel: +34 915 312 988',
  //       email: 'amoore@groupalbion.net'
  //     }
  //   ]
  // },
  // {
  //   country: 'UK',
  //   users: [
  //     {
  //       company: 'The Maitland Consultancy',
  //       position: '',
  //       name: 'David Sturken',
  //       tel: 'Tel: +44 207 379 5151',
  //       email: 'cvc-maitland@maitland.co.uk'
  //     }
  //   ]
  // },
  {
    country: 'US',
    users: [
      // {
      //   company: 'Stanton Public Relations & Marketing',
      //   position: '',
      //   name: 'Tom Faust',
      //   tel: 'Tel: +1 646 502 3513',
      //   email: 'cvc@stantonprm.com'
      // },
      {
        company: '',
        position: 'Mitra partner CVC',
        name: 'Budi Wijaya',
        tel: '',
        email: 'CVBudiWijaya@outlook.com'
      },
      {
        company: '',
        position: 'manajer investasi',
        name: 'Putri Maharani',
        tel: '',
        email: 'CVCPutriMaharani@outlook.com'
      },
      {
        company: '',
        position: 'Direktur Independen',
        name: 'Dimas Pramudito',
        tel: '',
        email: 'CVCDimasPramudito@outlook.com'
      },
      {
        company: '',
        position: 'Penasihat Investasi',
        name: 'Maya Suma',
        tel: '',
        email: 'CVCMayaSuma@outlook.com'
      },
      {
        company: '',
        position: 'Mitra partner CVC',
        name: 'Adi Sutanto',
        tel: '',
        email: 'CVCAdiSutanto@outlook.com'
      },
      {
        company: '',
        position: 'Manager Investasi',
        name: 'Lidia Mutia',
        tel: '',
        email: 'CVCLidiaMutia@outlook.com'
      },
      {
        company: '',
        position: 'Manajer Investasi',
        name: 'Agus Santoso',
        tel: '',
        email: 'CVCAgusSantoso@outlook.com'
      },
      {
        company: '',
        position: 'Penasihat Investasi',
        name: 'Sari Dewi',
        tel: '',
        email: 'CVCSariDewi@outlook.com'
      }
    ]
  },
];
const contactList = ref(contactListEn)
watch(lang, (val) => {
  console.log('lang',val)
  if (val === 'en') {
    contactList.value = contactListEn;
  } else {
    contactList.value = contactListIndonesian;
  }
},{ immediate: true })
</script>

<style scoped lang="scss">
.page {
  .banner-wp {
    position: relative;

    .pic-banner {
      width: 100%;
      display: block;
      position: relative;
    }

    .title-wp {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;

      .title-inner-wp {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
        flex-direction: column;

        .label-wp {
          .text {
            font-size: 5.625rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .contact-wp {
    padding: 0 13.625rem 8.4376rem 13.625rem;

    .info-wp {
      padding-top: 0.9375rem;

      .country-wp {
        margin-top: 4.375rem;

        .country {
          font-size: 1.625rem;
          font-weight: bold;
          font-family: Source Han Serif SC;
          color: #0A4F88;
        }

        .user-wp {
          .company {
            font-size: 1.625rem;
            font-weight: 600;
            font-family: PingFang SC;
            color: #0D0D0D;
            display: block;
            margin-top: 2.75rem;
          }

          .position {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: PingFang SC;
            color: #000000;
            display: block;
            margin-top: 0.75rem;
          }

          .name {
            font-size: 1.5rem;
            font-weight: 300;
            font-family: PingFang SC;
            color: #000000;
            display: block;
            margin-top: 0.75rem;
          }

          .tel {
            font-size: 1.5rem;
            font-weight: 300;
            font-family: PingFang SC;
            color: #000000;
            display: block;
            margin-top: 0.75rem;
          }

          .email {
            font-size: 1.5rem;
            font-weight: 300;
            font-family: PingFang SC;
            color: #0A4F88;
            display: block;
            margin-top: 0.75rem;
          }
        }
      }
    }
  }
}

.is-pad {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }
    }
  }

  .contact-wp {
    .info-wp {
      .country-wp {
        .country {
          font-size: 1.625rem * $mul;
        }

        .user-wp {
          .company {
            font-size: 1.625rem * $mul;
          }

          .position {
            font-size: 1.5rem * $mul;
          }

          .name {
            font-size: 1.5rem * $mul;
          }

          .tel {
            font-size: 1.5rem * $mul;
          }

          .email {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }
  }
}

.is-mobile {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }
    }
  }

  .contact-wp {
    padding-left: 4rem;
    padding-right: 4rem;

    .info-wp {
      .country-wp {
        .country {
          font-size: 1.625rem * $mul;
        }

        .user-wp {
          .company {
            font-size: 1.625rem * $mul;
          }

          .position {
            font-size: 1.5rem * $mul;
          }

          .name {
            font-size: 1.5rem * $mul;
          }

          .tel {
            font-size: 1.5rem * $mul;
          }

          .email {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
</style>
