export default {
    common: {
        'scrollToTop': 'scroll ke atas'
    },
    header: {
        about: 'Tentang',
        login: 'Login',
        search: 'Cari',
        strategies: 'Strategi',
        media: 'Media',
        strategiesSubNavDesc: 'CVC memiliki tujuh strategi pelengkap di ekuitas swasta, kedua, kredit dan infrastruktur melalui mana kita berinvestasi atas nama dana pensiun dan institusi utama lainnya.',
        findOutMore: 'Cari tahu lebih banyak',
        strategiesNavLabel1: 'Asia',
        strategiesNavLabel2: 'Peluang strategis',
        strategiesNavLabel3: 'Pertumbuhan',
        strategiesNavLabel4: 'Induksi',
        strategiesNavLabel5: 'Prasarana',
        mediaSubNavDesc: 'Berita terbaru dan wawasan dari seluruh jaringan CVC',
        mediaNavLabel1: 'Kontak medianame',
        mediaNavLabel2: 'Peringatan emailcomment',
        inviteCode: 'Undang kodecomment',
    },
    footer: {
        copyright: 'Hak cipta © 2024 CVC Capital Partners PLC',
        copyrightDesc: 'Semua angka pada 30 juni 2024, dan disesuaikan untuk mencerminkan akuisisi mitra modal DIF pada 1 juli 2024, kecuali dinyatakan sebaliknya. Belum termasuk investasi yang aktif pada 31 maret 2024.',
        text1: 'ketahanan',
        text2: 'Portfolio',
        text3: 'Pemegang saham',
        text4: 'Tanda tangani peringatan',
        text5: 'Persyaratan penggunaan',
        text6: 'Kebijakan kuki',
        text7: 'Kebijakan privasi',
        text8: 'Aksesibilitas',
        text9: 'Pernyataan perbudakan Modern',
        text10: 'Penutupan peraturan CVC',
        text11: 'kontak',
        text12: 'Laporan penipuan',
        text13: 'Sitemap',
        text14: 'Cookie setuju',
    },
    index: {
        ourPeople: 'Orang-orang kita',
        ourPeopleDesc: 'Dipimpin oleh kepemimpinan senior yang mendalam dan mapan, tim kewirausahaan kami adalah dasar dari kesuksesan CVC',
        position1: 'mitra',
        position2: 'Direktur strategi investasi',
        platform: 'Panggung',
        position: 'Posisi',
        office: 'Perkantoranname',
        people1: {
            desc1: 'Mitra Mitra, ikut dengan CVC pada tahun 2013. Dan aku asisten kantor kantor cabang di Indonesia dan berdomisili di Singapura.',
            desc2: 'Dengan diratakan dengan CVC, ia tahan terhadap dan tahan terhadap tahun PT Morgan Stanley Asia Indonesia Indonesia dan tahun.',
            desc3: 'Dan sekarang Magister juga dari universitas Texas, Amerika Amerika.',
            platform: 'Ekuitas swasta',
            office: 'Singapura'
        },
        people2: {
            desc1: '~ ~ Saat ini menjabat sebagai direktur di CVC, ~ ~ strategi investasi dan fokus penelitian ~ ~',
            desc2: 'Selama cuman, bertahun-tahun cuman cuman di pasar Indonesia dan Asia Tenggara, dilengkapi dengan tren tren tren tren tren tren tren tren tren tren tren.',
            office: 'Indonesia'
        },
        people3: {
            desc1: 'Mitra kamiyang melawan CVC pada tahun 2018.',
            desc2: 'Budi asisten dari CVC di Indonesia dan di Jakarta. Semakin besar pada pasarlania ia irani dan semakin besar.',
            desc3: 'Pengetahuan industri industri yang banyak dan banyak sekali yang baik, dia ramah dan ramah, dia sangat baik, dia sangat ramah,',
            office: 'Jakarta'
        },
        people4: {
            desc1: 'Saat ini berhasil mendahului kelas ekonomi dan keuangan pembangunan (indf), yang berhasil mendahului dan terus menanjak.',
            desc2: 'Setelah itu belum selesai. Setelah setelah itu belum selesai.',
            desc3: 'Tapidimas berkahir makro dan tapitapitapitapiyangyangbisatas untuk dasar ini penyemprotan dengan adanya investor di pasar yang ramai.',
        },
        people5: {
            desc1: 'Setelah Adi Sutanto bertemu dengan CVC pada tahun tahun 2018. Setelah setelah itu selesai, setelah setelah ekuitas selesai di Asia dan seterusnya. Ia berhasil Indonesia dengan Indonesia bersama dengan Indonesia. Dengan 20 tahun cinta sejatiku, ia cinta sejatiku untuk pasar pasar dan cinta sejatiku.',
            desc2: 'Sebelum bergabung dengan CVC, Adi menjabat sebagai Kepala Investasi memimpin di BCA berbagai itas, proyek Investasi. Ia mengirim saran dari benih yang yang, benih yang dikirim oleh kerajaan, ',
            desc3: 'Karakteristik jatuh: cuman pada matanya, dia adalah bangsawan dari negara dengan nilai tertinggi, dan setelah itu dia akan mulai dengan valuasi. Pemikiran trend pasar-pasar kembalianmu yang yang yang terus berjaya, terus berlanjut dan terus berlanjut dengan pasar pasar dan paskah.',
        }
    },
    strategies: {
        asia: {
            desc: 'CVC merupakan salah satu jaringan kantor regional terbesar dan terlama dari semua bisnis ekuitas swasta di Asia dan telah aktif di kawasan ini sejak tahun 1999',
            stat1Text: 'Aset di bawah manajemen',
            stat2Text: 'Investasi aktif',
            stat3Text: 'Profesional investasi',
            descList: {
                text1: 'Strategi ekuitas Asia swasta CVC difokuskan pada kontrol, co-control dan terstruktur investasi minoritas dalam bisnis berkualitas tinggi di sektor konsumen dan layanan inti di seluruh Asia. Nilai perusahaan biasanya antara 250 juta dolar dan 1,5 miliar dolar.',
                text2: 'Bisnis berkualitas tinggi di sektor pertumbuhan utama',
                text3: 'CVC berinvestasi pada bisnis yang beroperasi dalam industri domestik merespon perubahan di negara berkembang yang adalah:',
                text4: 'Sektor inti memperoleh manfaat dari kenaikan kelas menengah lokal, meningkatnya konsumsi dan kecenderungan demografis dan sektor-sektor lain di Asia, seperti layanan konsumen dan konsumen, ritel, layanan keuangan, perawatan kesehatan, TMT dan layanan bisnis',
                text5: 'Membangun bisnis dengan posisi pasar yang tinggi, reputasi yang baik dan reputasi yang solid',
                text6: 'miliar',
                text7: 'Dana disalurkan',
                text8: 'Lebih tentang CVC Asia',
                text9: 'Jaringan pan-regional terbesar di Asia',
                text10: 'CVC Asia investasi profesional terdiri dari cadangan mendalam pemimpin senior dengan pengetahuan lokal yang luas dan catatan yang kuat dari keberhasilan investasi ekuitas swasta di Asia.',
                text11: 'Studi kasus',
                text12: 'Menciptakan nilai pelestarian',
                text13: 'Kemampuan CVC untuk menyerap seluruh sumber daya global dalam situasi apapun memberinya keunggulan kompetitif ketika menciptakan nilai selama periode kepemilikan CVC.',
                text14: 'Lihat lebih',
                text15: 'PT. Softex',
                text16: 'Mendukung bisnis yang siap memberikan pertumbuhan',
                text17: 'Baca artikel',
                text18: 'Toko serba',
                text19: 'Mempercepat pertumbuhan',
                text20: 'menampilkan',
            }
        },
        opportunities: {
            title: 'Strategis',
            title1: 'Peluang',
            desc: 'CVC membentuk strategi peluang strategis pada tahun 2014 untuk berinvestasi pada bisnis berkualitas tinggi yang mungkin tidak sesuai dengan mandat ekuitas swasta tradisional',
            descList: {
                text1: 'Peluang strategis CVC bekerja sama dengan tim CVC dan tim sektor untuk mengidentifikasi peluang investasi.',
                text2: 'Strategi ini berfokus pada investasi ekuitas swasta di perusahaan dengan profil risiko yang lebih rendah, terutama di eropa dan amerika utara, serta kesempatan untuk bermitra dengan para keluarga pendiri atau yayasan mencari mitra jangka panjang.',
                text3: 'Nilai perusahaan dari perusahaan di mana peluang strategis strategi berinvestasi biasanya antara €1 miliar atau €5 miliar dan lebih, dengan jangka waktu yang lebih panjang sekitar enam sampai 15 tahun.',
                text4: 'Kualitas tinggi, bisnis yang stabil dengan jangka waktu penahanan yang panjang',
                text5: 'CVC in control, co-control atau minoritas mempengaruhi peluang dalam bisnis yang memiliki kualitas berikut:',
                text6: 'Kualitas tinggi, keuangan generatif dan bisnis yang stabil dan/atau aset dengan struktur modal yang aman',
                text7: 'Beroperasi di sektor volatilitas rendah dan lingkungan',
                text8: 'Menyediakan barang atau jasa penting ke sektor non-siklik',
                text9: 'Menawarkan peluang jangka panjang untuk pengembangan strategis',
                text10: 'Lebih mengenai peluang strategis CVC',
                text11: 'Berkomitmen untuk jangka panjang',
                text12: 'Tim peluang strategis CVC menawarkan modal jangka panjang kepada keluarga pendiri atau mitra bisnis berkualitas tinggi di sektor volatilitas rendah.',
            }
        },
        growth: {
            desc: 'Strategi pertumbuhan CVC diluncurkan pada tahun 2014 untuk menargetkan peluang besar dalam perusahaan yang terkait dengan teknologi tinggi',
            descList: {
                text1: 'Sektor pertumbuhan CVC menargetkan pertumbuhan menengah, perusahaan pertumbuhan tinggi yang beroperasi dalam sektor perangkat lunak dan layanan bisnis dengan teknologi dan, khususnya bisnis dimana teknologi digunakan sebagai sarana untuk menyediakan layanan misi yang penting bagi pelanggan. Hal ini terutama menargetkan investasi amerika utara dan eropa, membuat investasi ekuitas lebih dari 50 juta dolar.',
                text2: 'Perusahaan yang berhubungan dengan teknologi tinggi',
                text3: 'Melalui usaha pencarian sumber daya yang bersifat proaktif dan tematik, pertumbuhan CVC berinvestasi pada bisnis dengan karakteristik:',
                text4: 'Kecepatan pertumbuhan yang kuat',
                text5: 'Model pendapatan yang keka',
                text6: 'Pasar besar yang bisa diakses',
                text7: 'Diversifikasi pelanggan dan/atau segmentasi pelanggan yang kuat',
                text8: 'Ekonomi unit yang menarik',
                text9: 'Lebih lanjut tentang pertumbuhan CVC',
                text10: 'Pertumbuhan tinggi, keyakinan tinggi',
                text11: "Dengan strategi 'peta dan serangan' yang proaktif, pertumbuhan CVC menargetkan teknologi pertumbuhan tinggi dan investasi layanan bisnis dengan teknologi.",
                text12: 'Menciptakan nilai lestarikan',
                text13: 'Kemampuan CVC untuk menyerap seluruh sumber daya global dalam situasi apapun memberinya keunggulan kompetitif ketika menciptakan nilai selama periode kepemilikan CVC.',
                text14: 'Kount',
                text15: 'Sebuah kemitraan yang menguntungkan fokus pada inovasi',
                text16: 'Logika nirkabel',
                text17: 'Ekspansi pesat ke wilayah dan pasar baru',
            }
        },
        secondaries: {
            desc: 'Mitra sekunder CVC, yang didirikan melalui kemitraan strategis pada tahun 2022 dengan Glendower, berfokus pada pasar sekunder secara global',
            descList: {
                text1: 'Kantor pusat di London yang memiliki kantor di New York, mitra sekunder CVC (sebelumnya dikenal sebagai Glendower) memiliki tim yang beranggotakan 38 orang profesional investasi dan yang dipimpin oleh tim senior yang telah bekerja sama di secondari dua selama hampir 20 tahun. Perusahaan itu mengelola $13 miliar dalam perjanjian modal kelompok di berbagai dana yang didukung oleh investor institusional dan swasta terkemuka di seluruh dunia.',
                text2: 'Perusahaan ini menjadi bagian dari CVC ketika kemitraan strategis dibentuk pada tahun 2022, dengan akuisisi final diselesaikan pada tahun 2024.',
                text3: 'Kunjungi website mitra sekunder CVC',
                text4: 'Mitra sekunder CVC adalah manajer kedua menengah, fokus pada pembelian dan arus kas aset dengan potensi likuiditas jangka pendek. Perusahaan rekaman ini memiliki catatan resmi setelah menutup lebih dari 190 transaksi yang melibatkan lebih dari 1.500 kepentingan dana sejak awal. Tim tersebut mengikuti strategi dua arah yang berbeda yang didukung oleh pedoman investasi yang disiplin, menargetkan portofolio LP secondari dan GP-led secondari untuk memanfaatkan evolusi pasar sekunder.',
                text5: 'Portofolio dana keduali',
                text6: 'Mitra sekunder CVC memiliki skala operasional secara sistematis untuk secara sistematis menuliskan 5 juta dolar ke portofolio portofolio dan posisi dana tunggal, menyediakan solusi komprehensif untuk portfolios belakang, portofolio perusahaan pangan, dan aset-aset yang sulit untuk nilai.',
                text7: 'Portfolios belakang, membutuhkan kemampuan operasional untuk melakukan transaksi terhadap portofolio yang lebih tua, terpisah-pisah',
                text8: 'Kepentingan tunggal dengan pandangan yang berbeda dan akses manajer',
                text9: 'Portofolio dengan paparan publik yang signifikan membutuhkan akses ke platform investasi perbankan',
                text10: 'GP-led sekunder',
                text11: 'Dengan reputasi sebagai mitra pilihan yang dapat diandalkan untuk manajer keuangan dan investor karena pengalaman jangka panjang perusahaan dalam mengeksekusi transaksi kompleks. Mitra sekunder CVC mencatat transaksi GP-led berkisar dari $100 juta sampai $500, termasuk penjualan aset, rekapitalisasi dana, penjualan jalan raya, dan pemintal.',
                text12: 'Sangat berpengalaman, setelah aktif berinvestasi pada GP-led kedua sejak 2007, menyelesaikan lebih dari 60 transaksi untuk $3,4 miliar',
                text13: 'Reputasi sebagai pengganti utama transaksi dengan manajer pasar tengah',
                text14: 'Keahlian dalam memperoleh aset sulit untuk menghargai aset dengan menggunakan kemampuan kepemilikan dan pengaturan struktural untuk menanggung transaksi yang menantang di kelas aset yang berdekatan',
            }
        },
        infrastructure: {
            desc: 'Pada tahun 2024, CVC memperoleh saham mayoritas dalam manajer infrastruktur terkemuka, mitra modal DIF untuk menciptakan CVC DIF.',
            descList: {
                text1: 'UMM',
                text2: 'orang',
                text3: 'Didirikan pada tahun 2005, CVC DIF (sebelumnya mitra modal DIF) adalah manajer dana infrastruktur dengan posisi utama dalam mengelola investasi tengah pasar, terutama di eropa dan amerika utara.',
                text4: 'Ada dua strategi:',
                text5: 'Dana infrastruktur DIF yang berinvestasi pada perusahaan dan proyek yang membangun, memiliki dan mengoperasikan infrastruktur penting dalam pasar inti dan inti +. Biasanya dengan sampul kontrak jangka panjang, menawarkan perlindungan dan hasil yang kurang, dikombinasikan dengan kesempatan untuk tambahan nilai penciptaan jangka panjang.',
                text6: 'Nilai DIF menambahkan dana yang berinvestasi pada perusahaan dengan posisi kompetitif yang kuat, sering kali dikombinasikan dengan "beli dan bangun" yang menarik, menawarkan potensi pertumbuhan yang signifikan.',
                text7: 'Berbasis di 12 kantor, CVC DIF menawarkan pendekatan pasar yang unik menggabungkan kehadiran global dengan manfaat jaringan lokal yang kuat dan kemampuan investasi.',
                text8: 'Kunjungi website CVC DIF',
            }
        }
    },
    media: {
        contacts: {

        },
        emailAlerts: {
            text1: 'Tetap up-to-date dengan berita CVC terbaru.',
            text2: 'Pilih untuk menerima peringatan untuk berita kami dan/atau pengumuman peraturan, kirim email langsung ke kotak masuk anda.',
            text3: 'Berita',
            text4: 'Pengumuman peraturan',
            text5: 'Pilih untuk menerima peringatan untuk berita kami dan/atau pengumuman peraturan, kirim email langsung ke kotak masuk anda.',
            text6: 'Anda dapat unberlangganan melalui peringatan email yang anda terima.',
            text7: 'Nama depan',
            text8: 'Nama',
            text9: 'Alamat emailmu',
            text10: 'Tolong pilih setidaknya 1 bidang yang menarik',
            text11: 'Semua',
            text12: 'Aku mengkonfirmasi bahwa',
            text13: 'Telah membaca dan menyetujui persyaratan dan persyaratannya',
            text14: 'Dan persetujuan untuk memiliki informasi saya disimpan oleh CVC',
            text15: 'Recaptcha',
            text16: 'Kirim formulir',
            text17: 'Silakan mendaftar di bawah ini untuk pengumuman berita peraturan.',
            text18: 'Pilih dari opsi di bawah ini untuk menerima berita dan informasi keuangan lainnya melalui surel.',
            text19: 'Email',
            text20: 'Nama belakang',
            text21: 'Teman',
            text22: 'Negara',
            text23: 'Berlangganan',
            text24: 'Apa aku punya langganan?',
            text25: 'Situs ini dilindungi oleh reCAPTCHA dan Google',
            text26: 'Kebijakan privasi',
            text27: 'dan',
            text28: 'Persyaratan pelayanan',
            text29: 'terapkan',
            text30: 'Disediakan oleh',
            text31: 'Kebijakan layanan | privasi dan | kebijakan kue',
            text32: 'Rilis bursa saham',
        }
    }
}
