import axios from "axios";

const apiClient = axios.create({
    baseURL: window._config.APIBaseUrl,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
})

export default apiClient
