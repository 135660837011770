<template>
  <metainfo></metainfo>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex'
import { useMeta } from 'vue-meta'


export default {
  name: 'App',
  components: {
  },
  data: function () {
    return {
      isMacMobile: false,
      initWidth: 0,
      lastTouchEnd: 0
    }
  },
  created() {
  },
  mounted() {
    useMeta({
      meta: [
        {
          name: 'key',
          content: 'value'
        }
      ]
    })
    if (this._isAppleMobile()) {
      this.isMacMobile = true;
    }
    this.$nextTick(() => {
      if (this.isMacMobile) {
        this.initWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        this.onLoad()
      }
      window.addEventListener('resize', this.onResize)
    })
    this.setRem();
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    vW() {
      let v = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth // 当前窗口的宽度
      return v;
    }
  },
  watch: {
    vW: {
      handler(val) {
        console.log('vW', val)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      'changeDeviceType'
    ]),
    onResize() {
      this.setRem()
    },
    onLoad() {
      if (!this.isMacMobile) {
        return;
      }

      // document.addEventListener('touchstart', this.touchStart);
      // document.addEventListener('touchend', this.touchEnd, false);

      // 阻止双指放大
      // document.addEventListener('gesturestart', this.gestureStart);
    },
    // 阻止双击放大
    touchStart(event) {
      let newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // alert(this.initWidth)
      // alert(newWidth)
      console.log('initWidth', this.initWidth, newWidth, newWidth / this.initWidth)
      if (newWidth / this.initWidth < 0.2) {
        console.log('newWidth1:' + newWidth)
        if (event.touches.length > 1 ) {
          event.preventDefault();
        }
      }
      if (newWidth > this.initWidth) {
        console.log('newWidth2:' + newWidth)
        if (event.touches.length > 1 ) {
          event.preventDefault();
        }
      }
    },
    touchEnd(event) {
      let now = (new Date()).getTime();
      if (now - this.lastTouchEnd <= 300) {
        event.preventDefault();
      }
      this.lastTouchEnd = now;
      this.setRem();
    },
    gestureStart(event) {
      let newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      console.log('initWidth2', this.initWidth, newWidth, newWidth / this.initWidth)
      if (newWidth / this.initWidth < 0.2) {
        event.preventDefault();
      } if (newWidth > this.initWidth) {
        event.preventDefault();
      }
    },
    setRem() {
      let vW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth // 当前窗口的宽度

      let baseSize = 16;
      let designWidth = 1920;

      if (vW >= 900) {
        baseSize = 16 // 基准大小
        designWidth = 1920 // 设计稿的宽度
      }/* else if (vW >= 1400) {
        designWidth = 1700;
      } else if (vW >= 1200) {
        designWidth = 1500;
      } else if (vW >= 900) {
        designWidth = 1200;
      }*/ else if (vW > 800) {
        designWidth = 1200;
      } else if (vW > 700) {
        designWidth = 1100;
      } else if (vW > 600) {
        designWidth = 1000;
      } else if (vW > 500) {
        designWidth = 900;
      } else if (vW > 400) {
        designWidth = 800;
      } else if (vW > 300) {
        designWidth = 700;
      } else {
        designWidth = 600;
      }

      //  PC端
      let basePc = baseSize / designWidth // 表示3840的设计图,使用100PX的默认值
      // let vH = window.innerHeight // 当前窗口的高度
      // 非正常屏幕下的尺寸换算
      // let dueH = vW * designHeight / designWidth
      // if (vH < dueH) { // 当前屏幕高度小于应有的屏幕高度，就需要根据当前屏幕高度重新计算屏幕宽度
      //     vW = vH * designWidth / designHeight
      // }
      let rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
      // if (rem < 6) {
      //     rem = 6;
      // }
      // alert('vW' + vW)

      let deviceType = 'pc';
      if (!this._isMobile()) {
        if (vW >= 1200) {
          deviceType = 'pc';
        } else if (vW > 768) {
          deviceType = 'pad';
        } else {
          deviceType = 'mobile';
        }
      } else {
        deviceType = 'mobile';
      }
      // rem = 6;
      // rem = 4.79;
      // rem = 4;
      if (deviceType !== 'mobile') {
        //document.documentElement.style.fontSize = rem + "px"
        console.log("rem:" + rem, vW, basePc, baseSize, designWidth)
      } else {
        if (this._isiPhone()) {
          if (rem < 4) {
            rem = 4;
          }
        } else if (this._isiPad()) {
          if (rem < 6) {
            rem = 6;
          }
        } else {
          if (rem < 4) {
            rem = 4;
          }
        }
        console.log("rem:" + rem, vW, basePc, baseSize, designWidth)
        //document.documentElement.style.fontSize = rem + "px"
      }
      this.changeDeviceType(deviceType);
    },
    _isMobile() {
      let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return isMobile !== null
    },
    _isAppleMobile() {
      return this._isiPhone() || this._isiPad();
    },
    _isiPhone() {
      let u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
    _isiPad() {
      let ua = window.navigator.userAgent
      let isiPad = false
      if (/ipad/i.test(ua)) {
        isiPad = true
      }
      // iPad from IOS13
      let macApp = ua.match(/Macintosh/i) != null
      if (macApp) {
        // need to distinguish between Macbook and iPad
        let canvas = document.createElement('canvas')
        if (canvas != null) {
          let context =
              canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
          if (context) {
            let info = context.getExtension('WEBGL_debug_renderer_info')
            if (info) {
              let renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL)
              if (renderer.indexOf('Apple') !== -1)
                isiPad = true
            }
          }
        }
      }
      return isiPad;
    }
  }
}
</script>

<style>
html, body, div {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: hidden;
  width: 100%;
}
@font-face {
  font-family: 'PingFang SC';
  src: url("@/assets/fonts/PingFangSC-Regular.woff2") format('woff2');
}
@font-face {
  font-family: 'Source Han Serif SC';
  src: url("@/assets/fonts/SourceHanSerifSC-Regular.otf") format('opentype');
}
@font-face {
  font-family: 'Brown';
  src: url("@/assets/fonts/Brown-Light.woff2") format('woff2');
}
body::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
#app::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
/*@media screen and  (max-width: 1440px) {*/
/*  html {*/
/*    font-size: 12px;*/
/*  }*/
/*}*/
@media screen and  (max-width: 1200px) {
  html {
    font-size: 11px;
  }
}
@media screen and  (max-width: 960px) {
  html {
    font-size: 10px;
  }
}
@media screen and  (max-width: 768px) {
  html {
    font-size: 8px;
  }
}
</style>
