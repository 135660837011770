<template>
  <div :class="{'to-top': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}" @click="scrollToTop">
    <div class="btn-wp">
      <span class="text">{{$t('common.scrollToTop')}}</span>
      <svg-icon icon-class="to_top" />
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'BackToTop',
  data: function () {
    return {
      isShow: false,
    }
  },
  created() {
    // 页面滚动窗口监听事件
    // window.onscroll = function () {
    //   // 获取浏览器卷去的高度
    //   let high = document.documentElement.scrollTop || document.body.scrollTop; //兼容各浏览器
    //
    //   if (high >= 500) {
    //     this.isShow = true
    //   } else {
    //     this.isShow = false
    //   }
    // };
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ])
  },
  methods: {
    scrollToTop: function () {
      window.scrollTo({
        // top: document.documentElement.offsetHeight, //回到底部
        top: 0, //回到顶部
        left: 0,
        behavior: "smooth", //smooth 平滑；auto:瞬间
      });
    }
  }
}
</script>

<style scoped lang="scss">
.to-top {
  .btn-wp {
    border: 1px solid #535356;
    border-radius: 1.375rem;
    padding: 0.625rem 2.125rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    .text {
      font-size: 1rem;
      font-weight: 300;
      color: #FFFFFF;
      font-family: PingFang SC;
      text-decoration: none;
      display: block;
    }

    .svg-icon {
      margin-left: 0.6875rem;
      width: 0.75rem;
      height: 1.125rem;
    }
  }
}

.is-pad {
  $mul: 1;

  .btn-wp {
    .text {
      font-size: 1rem * $mul;
    }

    .svg-icon {
      width: 0.9375rem;
      height: 1.4375rem;
    }
  }
}

.is-mobile {
  $mul: 1;

  .btn-wp {
    .text {
      font-size: 1rem * $mul;
    }

    .svg-icon {
      width: 1.25rem;
      height: 1.9375rem;
    }
  }
}
</style>
