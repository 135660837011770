<template>
  <div :class="{'page': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <header-index :no-background="true" theme="white"></header-index>
    <div class="banner-wp">
      <img src="@/assets/images/asia.png" class="pic-banner" />
      <div class="title-wp">
        <div class="title-inner-wp">
          <div class="label-wp">
            <span class="text">{{$t('header.strategiesNavLabel1')}}</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.desc')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stat-wp">
      <div class="items-wp">
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="plot_bar" />
          </div>
          <div class="label-wp">
            <span class="text">€14bn</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat1Text')}}</span>
          </div>
        </div>
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="investment" />
          </div>
          <div class="label-wp">
            <span class="text">25</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat2Text')}}</span>
          </div>
        </div>
        <div class="item-wp">
          <div class="icon-wp">
            <svg-icon icon-class="users" />
          </div>
          <div class="label-wp">
            <span class="text">81</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.stat3Text')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-wp">
      <p class="text">
        {{$t('strategies.asia.descList.text1')}}
      </p>
      <p class="text bold">
        {{$t('strategies.asia.descList.text2')}}
      </p>
      <p class="text">
        {{$t('strategies.asia.descList.text3')}}
      </p>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.asia.descList.text4')}}
        </p>
      </div>
      <div class="text-wp">
        <div class="dot"></div>
        <p class="text">
          {{$t('strategies.asia.descList.text5')}}
        </p>
      </div>
      <div class="svg-wp">
        <img src="@/assets/images/asia-funds-committed.png" class="svg-icon" />
        <div class="fund-committed-wp">
          <div class="num-wp">
            <span class="text">€20</span>
          </div>
          <div class="unit-wp">
            <span class="text">{{$t('strategies.asia.descList.text6')}}</span>
          </div>
          <div class="text-wp">
            <span class="text">{{$t('strategies.asia.descList.text7')}}</span>
          </div>
        </div>
      </div>
      <p class="text more">
        {{$t('strategies.asia.descList.text8')}}
      </p>
      <p class="text largest">
        {{$t('strategies.asia.descList.text8')}}
      </p>
      <p class="text ln">
        {{$t('strategies.asia.descList.text10')}}
      </p>
    </div>
    <div class="case-wp">
      <div class="items-wp">
        <div class="item-wp">
          <div class="left-wp">
            <div class="label-wp">
              <span class="text">{{$t('strategies.asia.descList.text11')}}</span>
            </div>
            <div class="name-wp">
              <span class="text">{{$t('strategies.asia.descList.text12')}}</span>
            </div>
            <div class="text-wp">
              <span class="text">{{$t('strategies.asia.descList.text13')}}</span>
            </div>
            <div class="btn-wp">
              <div class="btn">
                <span class="text">{{$t('strategies.asia.descList.text14')}}</span>
              </div>
            </div>
          </div>
          <div class="right-wp" v-if="pagerIdx === 1">
            <div class="card-left-wp">
              <div class="label-wp">
                <div class="text">{{$t('header.strategiesNavLabel1')}}</div>
              </div>
              <div class="name-wp">
                <div class="text">{{$t('strategies.asia.descList.text15')}}</div>
              </div>
              <div class="text-wp">
                <div class="text">{{$t('strategies.asia.descList.text16')}}</div>
              </div>
              <div class="btn-wp">
                <div class="btn">
                  <svg-icon icon-class="eye" />
                  <span class="text">{{$t('strategies.asia.descList.text17')}}</span>
                </div>
              </div>
            </div>
            <div class="card-right-wp">
              <img src="@/assets/images/pt-softex-banner.png" class="pic-banner" />
            </div>
          </div>
          <div class="right-wp" v-if="pagerIdx === 2">
            <div class="card-left-wp">
              <div class="label-wp">
                <div class="text">{{$t('header.strategiesNavLabel1')}}</div>
              </div>
              <div class="name-wp">
                <div class="text">{{$t('strategies.asia.descList.text18')}}</div>
              </div>
              <div class="text-wp">
                <div class="text">{{$t('strategies.asia.descList.text19')}}</div>
              </div>
              <div class="btn-wp">
                <div class="btn">
                  <svg-icon icon-class="eye" />
                  <span class="text">{{$t('strategies.asia.descList.text17')}}</span>
                </div>
              </div>
            </div>
            <div class="card-right-wp">
              <img src="@/assets/images/matahari_thumbnail.png" class="pic-banner" />
            </div>
          </div>
        </div>
      </div>
      <div class="pager-wp">
        <div class="progress-wp">
          <div class="base-line-wp"></div>
          <div :class="{'current-line-wp': true, 'step-1': pagerIdx === 1, 'step-2': pagerIdx === 2}"></div>
        </div>
        <div class="num-wp">
          <span class="text">{{$t('strategies.asia.descList.text20')}} {{pagerIdx}}/2</span>
        </div>
        <div class="btn-wp">
          <div class="btn" @click="prePage">
            <svg-icon icon-class="arrow_left" />
          </div>
          <div class="btn right" @click="nextPage">
            <svg-icon icon-class="arrow_right" />
          </div>
        </div>
      </div>
    </div>
    <footer-index></footer-index>
  </div>
</template>

<script>
import HeaderIndex from "@/components/header";
import FooterIndex from "@/components/footer";
import SvgIcon from "@/components/svg/SvgIcon";
import { mapGetters } from 'vuex';

export default {
  name: 'StrategiesAsia',
  components: {
    SvgIcon,
    HeaderIndex,
    FooterIndex,
  },
  props: {},
  data: function () {
    return {
      pagerIdx: 1,
    }
  },
  created() {
    window.addEventListener('widthChange', this.widthChange)
  },
  mounted() {
  },
  unmounted() {
  },
  computed: {
    ...mapGetters([
        'deviceType'
    ])
  },
  methods: {
    prePage() {
      if (this.pagerIdx === 1) {
        return;
      }
      this.pagerIdx--;
    },
    nextPage() {
      if (this.pagerIdx === 2) {
        return;
      }
      this.pagerIdx++;
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .banner-wp {
    position: relative;

    .pic-banner {
      width: 100%;
      display: block;
      position: relative;
    }

    .title-wp {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;

      .title-inner-wp {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .label-wp {
          .text {
            font-size: 5.625rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
          }
        }

        .text-wp {
          margin-top: 4rem;
          max-width: 60.625rem;

          .text {
            font-size: 1.375rem;
            font-weight: 200;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
            text-align: center;
            display: block;
          }
        }
      }
    }
  }

  .stat-wp {
    padding: 7.3125rem 11.6875rem;
    box-sizing: border-box;

    .items-wp {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-wp {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0.625rem 6.1875rem 1px rgba(201,202,203,0.4);
        width: 29.375rem;
        height: 30.875rem;
        justify-content: center;
        border-radius: 1.5rem;

        .icon-wp {
          .svg-icon {
            width: 8.75rem;
            height: 8.75rem;
            display: block;
          }
        }

        .label-wp {
          margin-top: 1.25rem;

          .text {
            font-size: 4.25rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #0A4F88;
          }
        }

        .text-wp {
          margin-top: 2rem;

          .text {
            font-size: 1.25rem;
            font-weight: 600;
            font-family: PingFang SC;
            color: #000000;
          }
        }
      }
    }
  }

  .desc-wp {
    margin-top: 1.6875rem;
    padding: 0 12.0625rem;
    box-sizing: border-box;

    .text {
      font-size: 1.5rem;
      font-weight: 300;
      font-family: PingFang SC;
      letter-spacing: 0.1rem;
      color: #000000;
      line-height: 1.2;
    }

    .bold {
      font-size: 1.625rem;
      font-weight: bold;
      font-family: Source Han Serif SC;
      color: #0A4F88;
    }

    .text-wp {
      display: flex;

      .dot {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background: #0A4F88;
        margin-right: 2rem;
        flex-shrink: 0;
        margin-top: 0.875rem;
      }

      .text {
        margin-top: 0;
      }
    }

    .svg-wp {
      width: 100%;
      margin-top: 1.5rem;
      position: relative;

      .svg-icon {
        width: 100%;
        height: 100%;
        display: block;
      }

      .fund-committed-wp {
        position: absolute;
        right: 6.3125rem;
        bottom: -11.25rem;
        width: 32.75rem;
        height: 27.1875rem;
        border-radius: 1.5rem;
        box-shadow: 0px 0.625rem 6.1875rem 1px rgba(201,202,203,0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #FFFFFF;

        .num-wp {
          .text {
            font-size: 4.75rem;
            color: #0A4F88;
            font-weight: bold;
            font-family: Source Han Serif SC;
          }
        }

        .unit-wp {
          margin-top: 3rem;

          .text {
            font-size: 4.75rem;
            color: #0A4F88;
            font-weight: bold;
            font-family: Source Han Serif SC;
          }
        }

        .text-wp {
          margin-top: 3.625rem;

          .text {
            font-size: 1.5rem;
            color: #0A4F88;
            font-weight: 300;
            font-family: PingFang SC;
          }
        }
      }
    }

    .more {
      margin-top: 13rem;
      font-weight: 600;
      font-size: 1.875rem;
      line-height: 2.375rem;
    }

    .largest {
      margin-top: 3.25rem;
      font-weight: 600;
      font-size: 3.125rem;
    }

    .ln {
      width: 47.9375rem;
    }
  }

  .case-wp {
    margin-top: 14.6875rem;
    padding: 0 6.25rem 7.5625rem 12.0625rem;
    box-sizing: border-box;

    .items-wp {
      .item-wp {
        display: flex;

        .left-wp {
          width: 47.9375rem;
          flex-shrink: 0;
          padding-right: 3.75rem;

          .label-wp {
            margin-top: 1.875rem;

            .text {
              font-size: 1.875rem;
              font-weight: 600;
              font-family: PingFang SC;
              color: #000000;
            }
          }

          .name-wp {
            margin-top: 3.25rem;

            .text {
              font-size: 3.125rem;
              font-weight: 600;
              font-family: Source Han Serif SC;
              color: #000000;
            }
          }

          .text-wp {
            margin-top: 3.25rem;

            .text {
              font-size: 1.5rem;
              font-weight: 300;
              font-family: PingFang SC;
              color: #000000;
            }
          }

          .btn-wp {
            margin-top: 5.0625rem;

            .btn {
              padding: 1.25rem 5.275rem;
              box-sizing: border-box;
              border: 1px solid #009174;
              border-radius: 2.5625rem;
              width: fit-content;

              .text {
                font-size: 1.3125rem;
                font-weight: 400;
                font-family: PingFang SC;
                color: #000000;
              }
            }
          }
        }

        .right-wp {
          flex-grow: 1;
          padding-left: 3.3125rem;
          box-sizing: border-box;
          box-shadow: 1px 0.625rem 1.875rem 1px rgba(201,202,203,0.33);
          border-radius: 1.5rem;
          display: flex;

          .card-left-wp {
            flex-grow: 1;

            .label-wp {
              margin-top: 4.5625rem;

              .text {
                font-size: 1.5rem;
                font-weight: 600;
                font-family: PingFang SC;
                color: #0A4F88;
              }
            }

            .name-wp {
              margin-top: 1.5rem;

              .text {
                font-size: 2.5rem;
                font-weight: 600;
                font-family: Source Han Serif SC;
                color: #000000;
              }
            }

            .text-wp {
              margin-top: 2rem;

              .text {
                font-size: 1.5rem;
                font-weight: 300;
                font-family: PingFang SC;
                color: #000000;
              }
            }

            .btn-wp {
              margin-top: 3.125rem;

              .btn {
                padding: 0.9375rem 3.4375rem;
                box-sizing: border-box;
                background: #F3F3F3;
                border-radius: 2.5625rem;
                width: fit-content;
                display: flex;
                align-items: center;

                .svg-icon {
                  width: 1.75rem;
                  height: 1.75rem;
                  display: block;
                }

                .text {
                  font-size: 1.3125rem;
                  font-weight: 600;
                  font-family: PingFang SC;
                  color: #000000;
                  margin-left: 0.625rem;
                }
              }
            }
          }

          .card-right-wp {
            flex-shrink: 0;

            .pic-banner {
              width: 20.125rem;
              height: 31.9375rem;
              display: block;
            }
          }
        }
      }
    }

    .pager-wp {
      margin-top: 4.6875rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .progress-wp {
        position: relative;

        .base-line-wp {
          width: 30.75rem;
          height: 0.125rem;
          background: #B4C8D7;
        }

        .current-line-wp {
          width: 15.275rem;
          position: absolute;
          height: 0.1875rem;
          background: #009174;
          top: -0.0625rem;
        }

        .step-1 {
          left: 0;
        }

        .step-2 {
          left: 15.275rem;
        }
      }

      .num-wp {
        margin-left: 1.25rem;

        .text {
          font-size: 1.25rem;
          font-weight: 300;
          font-family: PingFang SC;
          color: #000000;
        }
      }

      .btn-wp {
        margin-left: 1.625rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        .btn {
          padding: 1.125rem 1.8125rem;
          box-sizing: border-box;
          border: 1px solid #959595;
          border-radius: 1.625rem;

          .svg-icon {
            width: 1.5rem;
            height: 1.125rem;
            display: block;
          }
        }

        .right {
          margin-left: 1.125rem;
        }
      }
    }
  }
}
.is-pad {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .stat-wp {
    .items-wp {
      .item-wp {
        .label-wp {
          .text {
            font-size: 4.25rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.25rem * $mul;
          }
        }
      }
    }
  }

  .desc-wp {
    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .svg-wp {
      .fund-committed-wp {
        .num-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .unit-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }

    .more {
      font-size: 1.875rem * $mul;
    }

    .largest {
      font-size: 3.125rem * $mul;
    }
  }

  .case-wp {
    .items-wp {
      .item-wp {
        .left-wp {
          .label-wp {
            .text {
              font-size: 1.875rem * $mul;
            }
          }

          .name-wp {
            .text {
              font-size: 3.125rem * $mul;
            }
          }

          .text-wp {
            .text {
              font-size: 1.5rem * $mul;
            }
          }

          .btn-wp {
            .btn {
              .text {
                font-size: 1.3125rem * $mul;
              }
            }
          }
        }

        .right-wp {
          .card-left-wp {
            .label-wp {
              .text {
                font-size: 1.5rem * $mul;
              }
            }

            .name-wp {
              .text {
                font-size: 2.5rem * $mul;
              }
            }

            .text-wp {
              .text {
                font-size: 1.5rem * $mul;
              }
            }

            .btn-wp {
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }
          }
        }
      }
    }

    .pager-wp {
      .num-wp {
        .text {
          font-size: 1.25rem * $mul;
        }
      }
    }
  }
}
.is-mobile {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .stat-wp {
    padding-left: 6.25rem;
    padding-right: 6.25rem;

    .items-wp {
      flex-direction: column;

      .item-wp {
        margin-top: 3.75rem;
        width: 100%;

        .label-wp {
          .text {
            font-size: 4.25rem * $mul;
          }
        }

        .text-wp {
          .text {
            font-size: 1.25rem * $mul;
          }
        }
      }
    }
  }

  .desc-wp {
    padding-left: 6.25rem;
    padding-right: 6.25rem;

    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .svg-wp {
      .fund-committed-wp {
        height: auto;
        width: 70%;
        right: 50%;
        margin-right: -35%;
        bottom: -7.5rem;

        .num-wp {
          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .unit-wp {
          margin-top: 1rem;

          .text {
            font-size: 4.75rem * $mul;
          }
        }

        .text-wp {
          margin-top: 1rem;

          .text {
            font-size: 1.5rem * $mul;
          }
        }
      }
    }

    .more {
      font-size: 1.875rem * $mul;
    }

    .largest {
      font-size: 3.125rem * $mul;
      line-height: 1.2;
    }

    .ln {
      line-height: 1.2;
      width: 100%;
    }
  }

  .case-wp {
    padding: 0 10%;

    .items-wp {
      .item-wp {
        flex-direction: column;

        .left-wp {
          width: 100%;

          .label-wp {
            .text {
              font-size: 1.875rem * $mul;
            }
          }

          .name-wp {
            .text {
              font-size: 3.125rem * $mul;
            }
          }

          .text-wp {
            .text {
              font-size: 1.5rem * $mul;
            }
          }

          .btn-wp {
            .btn {
              .text {
                font-size: 1.3125rem * $mul;
              }
            }
          }
        }

        .right-wp {
          margin-top: 2rem;

          .card-left-wp {
            .label-wp {
              .text {
                font-size: 1.5rem * $mul;
              }
            }

            .name-wp {
              .text {
                font-size: 2.5rem * $mul;
              }
            }

            .text-wp {
              .text {
                font-size: 1.5rem * $mul;
              }
            }

            .btn-wp {
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }
          }
        }
      }
    }

    .pager-wp {
      margin-bottom: 2rem;

      .progress-wp {
        flex-grow: 1;

        .base-line-wp {
          width: 100%;
        }

        .current-line-wp {
          width: 50%;
        }

        .step-2 {
          left: 50%;
        }
      }

      .num-wp {
        flex-shrink: 0;
        .text {
          font-size: 1.25rem * $mul;
        }
      }

      .btn-wp {
        flex-shrink: 0;
      }
    }
  }
}
</style>
<style lang="scss">
</style>
