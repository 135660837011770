import {createStore} from 'vuex';

const store = createStore({
    state: {
        deviceType: 'pc'
    },
    getters: {
        deviceType(state) {
            return state.deviceType
        }
    },
    mutations: {
        changeDeviceType(state, type) {
            state.deviceType = type
        }
    },
    actions: {
        changeDeviceType(context, type) {
            context.commit('changeDeviceType', type)
        }
    }
})

export default store
