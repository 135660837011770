<template>
  <div :class="{'page': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}">
    <header-index :no-background="true" theme="white"></header-index>
    <div class="banner-wp">
      <img src="@/assets/images/media-alert.png" class="pic-banner" />
      <div class="title-wp">
        <div class="title-inner-wp">
          <div class="label-wp">
            <span class="text">{{$t('header.mediaNavLabel2')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-wp">
      <p class="text bold">
        {{$t('media.emailAlerts.text1')}}
      </p>
      <p class="text">
        {{$t('media.emailAlerts.text2')}}
      </p>
      <div class="form-wp">
        <div class="tab-wp">
          <div :class="{'tab-item-wp': true, active: formIdx === 1}"  @click="switchTab(1)">
            <div class="text-wp">
              <span class="text">{{$t('media.emailAlerts.text3')}}</span>
            </div>
          </div>
          <div :class="{'tab-item-wp': true, active: formIdx === 2}" @click="switchTab(2)">
            <div class="text-wp">
              <span class="text">{{$t('media.emailAlerts.text4')}}</span>
            </div>
          </div>
        </div>
        <div class="form-content-wp">
          <div class="content-item-wp form1" v-if="formIdx === 1">
            <p class="text">
              {{$t('media.emailAlerts.text5')}}
            </p>
            <p class="text">
              {{$t('media.emailAlerts.text6')}}
            </p>
            <div class="form-input-wp">
              <div class="input-line-wp">
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text7')}} *</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form1Data.firstName" class="input" />
                  </div>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text8')}} *</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form1Data.surname" class="input" />
                  </div>
                </div>
              </div>
              <div class="input-line-wp">
                <div class="input-item-wp fill">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text9')}} *</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form1Data.email" class="input" />
                  </div>
                </div>
              </div>
              <p class="text bold in-form">
                {{$t('media.emailAlerts.text10')}} *
              </p>
              <div class="checkbox-line-wp first">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-1" type="checkbox" v-model="interestAll" class="checkbox" />
                    <label for="media-alert-form-news-1" class="checkbox-label">{{$t('media.emailAlerts.text11')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-2" type="checkbox" value="asia" v-model="form1Data.interest"  class="checkbox" />
                    <label for="media-alert-form-news-2" class="checkbox-label">{{$t('header.strategiesNavLabel1')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-3" type="checkbox" value="Strategic Opportunities" v-model="form1Data.interest" class="checkbox" />
                    <label for="media-alert-form-news-3" class="checkbox-label">{{$t('header.strategiesNavLabel2')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-4" type="checkbox" value="Growth" v-model="form1Data.interest"  class="checkbox" />
                    <label for="media-alert-form-news-4" class="checkbox-label">{{$t('header.strategiesNavLabel3')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-5" type="checkbox" value="Secondaries" v-model="form1Data.interest"  class="checkbox" />
                    <label for="media-alert-form-news-5" class="checkbox-label">{{$t('header.strategiesNavLabel4')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-6" type="checkbox" value="Infrastructure" v-model="form1Data.interest"  class="checkbox" />
                    <label for="media-alert-form-news-6" class="checkbox-label">{{$t('header.strategiesNavLabel5')}}</label>
                  </div>
                </div>
              </div>
              <div class="checkbox-line-wp confirm">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="media-alert-form-news-7" type="checkbox" name="form1DataInterest" v-model="form1Data.accept" class="checkbox" />
                    <label for="media-alert-form-news-7" class="checkbox-label">
                      <span class="label-text">{{$t('media.emailAlerts.text12')}}</span>
                      <span class="label-text blue">{{$t('media.emailAlerts.text13')}}</span>
                      <span class="label-text">{{$t('media.emailAlerts.text14')}}</span>
                    </label>
                  </div>
                </div>
              </div>
<!--              <p class="text bold recaptcha">-->
<!--                {{$t('media.emailAlerts.text15')}}-->
<!--              </p>-->
<!--              <vue-recaptcha-->
<!--                  sitekey="6LeYBW4qAAAAAHgP9AVoKDRKf2SqbjbfenAaf1cs"-->
<!--                  size="normal"-->
<!--                  theme="light"-->
<!--                  hl="zh-TW"-->
<!--                  @verify="recaptchaCallback"-->
<!--                  @expire="recaptchaExpiredCallback"-->
<!--                  @fail="recaptchaErrorCallback">-->
<!--              </vue-recaptcha>-->
              <div class="btn-wp">
                <div class="btn" @click="newsSubmit">
                  <span class="text">{{$t('media.emailAlerts.text16')}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item-wp form2" v-if="formIdx === 2">
            <p class="text">
              {{$t('media.emailAlerts.text17')}}
            </p>
            <p class="text bold">
              {{$t('media.emailAlerts.text18')}}
            </p>
            <div class="form-input-wp">
              <div class="checkbox-line-wp">
                <div class="checkbox-item-wp">
                  <div class="checkbox-wp">
                    <input id="stockExchangeReleases" type="checkbox" name="stockExchangeReleases" class="checkbox" />
                    <label for="stockExchangeReleases" class="checkbox-label">{{$t('media.emailAlerts.text32')}}</label>
                  </div>
                </div>
              </div>
              <div class="input-line-wp" v-if="deviceType !== 'mobile'">
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text19')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.email" class="input" />
                  </div>
                  <span class="text-star">*</span>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text7')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.firstName" class="input" />
                  </div>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text20')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.lastName" class="input" />
                  </div>
                </div>
              </div>
              <div class="input-line-wp line2" v-if="deviceType !== 'mobile'">
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text21')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.company" class="input" />
                  </div>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text22')}}</span>
                  </div>
                  <div class="input-wp">
                    <select class="select-wp">
                      <option :value="country" v-for="(country, cIdx) in countryList" :key="`country_${cIdx}`">{{country}}</option>
                    </select>
                    <svg-icon icon-class="arrow_type2_down_black" />
                  </div>
                </div>
                <div class="input-item-wp" style="visibility: hidden">
                  <div class="text-wp">
                    <span class="text"></span>
                  </div>
                  <div class="input-wp"></div>
                </div>
              </div>
              <div class="input-line-wp" v-if="deviceType === 'mobile'">
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text19')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.email" class="input" />
                  </div>
                  <span class="text-star">*</span>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text7')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.firstName" class="input" />
                  </div>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text20')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.lastName" class="input" />
                  </div>
                </div>
              </div>
              <div class="input-line-wp line2" v-if="deviceType === 'mobile'">
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text21')}}</span>
                  </div>
                  <div class="input-wp">
                    <input type="text" v-model="form2Data.company" class="input" />
                  </div>
                </div>
                <div class="input-item-wp">
                  <div class="text-wp">
                    <span class="text">{{$t('media.emailAlerts.text22')}}</span>
                  </div>
                  <div class="input-wp">
                    <select class="select-wp">
                      <option :value="country" v-for="(country, cIdx) in countryList" :key="`country_${cIdx}`">{{country}}</option>
                    </select>
                    <svg-icon icon-class="arrow_type2_down_black" />
                  </div>
                </div>
                <div class="input-item-wp" style="visibility: hidden">
                  <div class="text-wp">
                    <span class="text"></span>
                  </div>
                  <div class="input-wp"></div>
                </div>
              </div>
              <div class="btn-wp" @click="subscribe">
                <div class="btn">
                  <span class="text">{{$t('media.emailAlerts.text23')}}</span>
                </div>
              </div>
              <p class="text bold blue1">
                {{$t('media.emailAlerts.text24')}}?
              </p>
              <p class="text">
                {{$t('media.emailAlerts.text25')}} <span class="blue1">{{$t('media.emailAlerts.text26')}}</span> {{$t('media.emailAlerts.text27')}} <span class="blue1">{{$t('media.emailAlerts.text28')}}</span> {{$t('media.emailAlerts.text29')}}.
              </p>
              <div class="policy-wp">
                <div class="supplied-wp">
                  <span class="text">{{$t('media.emailAlerts.text30')}} ©</span>
                  <svg-icon icon-class="supplied" />
                </div>
                <div class="text-wp">
                  <span class="text">{{$t('media.emailAlerts.text31')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-index></footer-index>
  </div>
</template>
<script setup>
//import {ref} from "vue";
//import config from '../../../public/config';

// const setKey = ref(config.recaptcha.siteKey);
</script>
<script>
import HeaderIndex from "@/components/header";
import FooterIndex from "@/components/footer";
// import vueRecaptcha from 'vue3-recaptcha2';
import {mapGetters} from "vuex";
import {layer} from 'vue3-layer';
import SvgIcon from "@/components/svg/SvgIcon";
import apiClient from "@/util/apiClient";

export default {
  name: 'MediaEmailAlert',
  components: {
    SvgIcon,
    HeaderIndex,
    FooterIndex,
    // vueRecaptcha
  },
  props: {},
  data: function () {
    return {
      formIdx: 1,
      form1Data: {
        firstName: '',
        surname: '',
        email: '',
        interest: [],
        // gRecaptchaResponse: '',
        accept: false,
      },
      interestAll: false,
      form2Data: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        country: ''
      },
      countryList: [
          'Indonesia',
      ]
    }
  },
  created() {
  },
  mounted() {
    //console.log('config', config)
  },
  unmounted() {
  },
  watch: {
    interestAll(val) {
      if (val) {
        this.form1Data.interest.push('Strategic Opportunities')
        this.form1Data.interest.push('asia')
        this.form1Data.interest.push('Growth')
        this.form1Data.interest.push('Secondaries')
        this.form1Data.interest.push('Infrastructure')
      } else {
        this.form1Data.interest = [];
      }
    }
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ])
  },
  methods: {
    switchTab(idx) {
      if (this.formIdx === idx) {
        return;
      }
      this.formIdx = idx;
    },
    newsSubmit() {
      console.log('newsSubmit')
      if (!this.form1Data.firstName) {
        layer.msg('first name empty')
        return;
      }
      if (!this.form1Data.surname) {
        layer.msg('surname empty')
        return;
      }
      if (!this.form1Data.email) {
        layer.msg('email address empty')
        return;
      }
      if (this.form1Data.interest.length === 0) {
        layer.msg('no interest checked')
        return;
      }
      if (!this.form1Data.accept) {
        layer.msg('you need read and agree to the terms and conditions')
        return;
      }
      // if (!this.form1Data.gRecaptchaResponse) {
      //   layer.msg('please check the recaptcha')
      // }

      console.log('form1Data', this.form1Data)
      let formData = {
        first_name: this.form1Data.firstName,
        surname: this.form1Data.surname,
        email: this.form1Data.email,
        interests: this.form1Data.interest,
        // gRecaptcha: this.form1Data.gRecaptchaResponse
      }
      apiClient.post('/front/media_alert/news', formData).then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if (data.code === 0) {
            layer.msg('submit success');
            this.form1Data.firstName = '';
            this.form1Data.surname = '';
            this.form1Data.email = '';
            this.form1Data.interest = [];
            this.form1Data.accept = false;
            // this.form1Data.gRecaptchaResponse = '';
          } else {
            layer.msg(data.msg)
          }
        } else {
          layer.msg('network error')
        }
      }).catch((e) => {
        console.log('e', e)
        layer.msg('network error')
      })
    },
    subscribe() {
      console.log('subscribe')
      if (!this.form2Data.firstName) {
        layer.msg('first name empty')
        return;
      }
      if (!this.form2Data.lastName) {
        layer.msg('last name empty')
        return;
      }
      if (!this.form2Data.email) {
        layer.msg('email address empty')
        return;
      }
      if (this.form2Data.company) {
        layer.msg('company empty')
        return;
      }
      if (!this.form2Data.country) {
        layer.msg('country empty')
        return;
      }
      console.log('form2Data', this.form2Data)
      let formData = {
        first_name: this.form2Data.firstName,
        last_name: this.form2Data.lastName,
        email: this.form2Data.email,
        company: this.form2Data.company,
        country: this.form2Data.country
      }
      apiClient.post('/front/media_alert/subscribe', formData).then((res) => {
        if (res.status === 200) {
          let data = res.data;
          layer.msg(data.msg)
          if (data.code === 0) {
            layer.msg('subscribe success');
            this.form2Data.firstName = '';
            this.form2Data.lastName = '';
            this.form2Data.email = '';
            this.form2Data.company = '';
            this.form2Data.country = '';
          } else {
            layer.msg(data.msg)
          }
        } else {
          layer.msg('network error')
        }
      }).catch((e) => {
        console.log('e', e)
        layer.msg('network error')
      })
    },
    recaptchaCallback(params) {
      console.log('params', params, 'recaptcha success')
      this.form1Data.gRecaptchaResponse = params;
    },
    recaptchaExpiredCallback(params) {
      console.log('params', params, 'recaptcha expired')
      this.form1Data.gRecaptchaResponse = ''
    },
    recaptchaErrorCallback(params) {
      console.log('params', params, 'recaptcha error')
      this.form1Data.gRecaptchaResponse = ''
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .banner-wp {
    position: relative;

    .pic-banner {
      width: 100%;
      display: block;
      position: relative;
    }

    .title-wp {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;

      .title-inner-wp {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
        flex-direction: column;

        .label-wp {
          .text {
            font-size: 5.625rem;
            font-weight: bold;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
          }
        }

        .text-wp {
          margin-top: 4rem;
          max-width: 60.625rem;

          .text {
            font-size: 1.375rem;
            font-weight: 200;
            font-family: Source Han Serif SC;
            color: #FFFFFF;
            text-align: center;
            display: block;
          }
        }
      }
    }
  }

  .desc-wp {
    margin-top: 4.0625rem;
    padding: 0 13.625rem;
    box-sizing: border-box;

    .text {
      font-size: 1.5rem;
      line-height: 1.2;
      font-weight: 300;
      font-family: PingFang SC;
      letter-spacing: 0.1rem;
      color: #000000;
    }

    .bold {
      font-size: 1.625rem;
      font-weight: bold;
      font-family: Source Han Serif SC;
      color: #000000;
    }

    .in-form {
      font-size: 1.6875rem;
    }

    .recaptcha {
      margin-top: 3.25rem;
    }

    .form-wp {
      margin-top: 7.4375rem;
      margin-bottom: 9.9375rem;

      .tab-wp {
        display: flex;

        .tab-item-wp {
          width: 50%;
          cursor: pointer;

          .text-wp {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2.875rem 0;
            border-bottom: 0.125rem solid #F3F3F3;

            .text {
              font-size: 2rem;
              font-weight: 600;
              font-family: PingFang SC;
              color: #0A4F88;
            }
          }
        }

        .active {
          .text-wp {
            border-bottom: 0.125rem solid #009174;
          }
        }
      }

      .form-content-wp {
        padding-top: 5.6875rem;

        .form1 {
          .form-input-wp {
            padding: 0px 7.8125rem 0 9.625rem;
            box-sizing: border-box;

            .input-line-wp {
              display: flex;
              align-items: center;
              margin-top: 4.9375rem;
              justify-content: space-between;
              width: 73rem;

              .input-item-wp {
                width: 44%;

                .text-wp {
                  .text {
                    font-size: 1.6875rem;
                    font-weight: 600;
                    color: #000000;
                    font-family: PingFang SC;
                  }
                }

                .input-wp {
                  margin-top: 2.3125rem;
                  width: 100%;
                  height: 5.125rem;
                  background: #FFFFFF;
                  border: 1px solid #707070;
                  border-radius: 1.25rem;
                  display: flex;
                  align-items: center;
                  padding: 0 1.25rem;
                  box-sizing: border-box;

                  .input {
                    background: none;
                    height: 2.125rem;
                    border: none;
                    font-size: 1.5rem;
                    color: #333333;
                    width: 100%;
                  }

                  .input::placeholder {
                    color: #0A4F88;
                  }

                  .input:focus {
                    border: none;
                    outline: none;
                  }
                }
              }

              .fill {
                width: 73rem;

                .input-wp {
                  width: 100%;
                }
              }
            }

            .checkbox-line-wp {
              margin-top: 1.25rem;

              .checkbox-item-wp {
                .checkbox-wp {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .checkbox {
                    background: #FFFFFF;
                    border: 1px solid #949494;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 0.5625rem;
                    cursor: pointer;
                    flex-shrink: 0;
                  }

                  .checkbox-label {
                    margin-left: 1.25rem;
                    font-size: 1.6875rem;
                    color: #000000;
                    font-weight: 600;
                    font-family: PingFang SC;
                    cursor: pointer;
                  }
                }
              }
            }

            .checkbox-line-wp.first {
              margin-top: 2.5rem;
            }

            .checkbox-line-wp.confirm {
              margin-top: 3.75rem;

              .checkbox-item-wp {
                .checkbox-wp {
                  align-items: flex-start;

                  .checkbox-label {
                    .label-text.blue {
                      color: #0202EE;
                      margin: 0 0.375rem;
                    }
                  }
                }
              }
            }

            .btn-wp {
              margin-top: 7.5rem;

              .btn {
                padding: 1.25rem 3.6875rem;
                box-sizing: border-box;
                border-radius: 3.125rem;
                border: 1px solid #009174;
                width: fit-content;
                cursor: pointer;

                .text {
                  font-size: 1.3125rem;
                  color: #0A4F88;
                  font-weight: 600;
                  font-family: PingFang SC;
                  display: block;
                }
              }
            }
          }
        }

        .form2 {
          .form-input-wp {
            .input-line-wp {
              display: flex;
              align-items: center;
              margin-top: 4.9375rem;
              justify-content: space-between;

              .input-item-wp {
                position: relative;

                .text-wp {
                  .text {
                    font-size: 1.6875rem;
                    font-weight: 600;
                    color: #000000;
                    font-family: PingFang SC;
                  }
                }

                .input-wp {
                  margin-top: 2.3125rem;
                  width: 28.0625rem;
                  height: 4.875rem;
                  background: #FFFFFF;
                  border: 1px solid #707070;
                  border-radius: 0.875rem;
                  display: flex;
                  align-items: center;
                  padding: 0 1.25rem;
                  box-sizing: border-box;

                  .input {
                    background: none;
                    height: 2.125rem;
                    border: none;
                    font-size: 1.5rem;
                    color: #333333;
                    width: 100%;
                  }

                  .input::placeholder {
                    color: #0A4F88;
                  }

                  .input:focus {
                    border: none;
                    outline: none;
                  }
                }

                .text-star {
                  position: absolute;
                  right: -2.25rem;
                  bottom: 0;
                  font-size: 1.6875rem;
                  color: #FF0000;
                  font-weight: 600;
                  height: 4.875rem;
                  line-height: 4.875rem;
                }
              }
            }

            .line2 {
              margin-top: 2.3125rem;

              .input-wp {
                padding-left: 0 !important;
                padding-right: 0 !important;
                position: relative;

                .select-wp {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  padding: 0px 1.25rem;
                  border: none;
                  background: none;
                  color: #333;
                  font-size: 1.5rem;
                  width: 100%;
                  height: 4.875rem !important;

                  option {
                    padding: 0.3125rem;
                    background-color: #f9f9f9;
                    color: #333;
                    height: 4.875rem;
                  }
                }

                .select-wp:focus {
                  outline: none;
                }

                .svg-icon {
                  width: 1.25rem;
                  height: 1.25rem;
                  display: block;
                  position: absolute;
                  top: 1.8125rem;
                  right: 1.25rem;
                }
              }
            }

            .checkbox-line-wp {
              margin-top: 1.25rem;

              .checkbox-item-wp {
                .checkbox-wp {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .checkbox {
                    background: #FFFFFF;
                    border: 1px solid #949494;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 0.5625rem;
                    cursor: pointer;
                    flex-shrink: 0;
                  }

                  .checkbox-label {
                    margin-left: 1.25rem;
                    font-size: 1.6875rem;
                    color: #000000;
                    font-weight: 600;
                    font-family: PingFang SC;
                    cursor: pointer;
                  }
                }
              }
            }

            .checkbox-line-wp.first {
              margin-top: 2.5rem;
            }

            .blue1 {
              color: #0A4F88;
            }

            .btn-wp {
              margin-top: 7.5rem;

              .btn {
                padding: 1.25rem 3.6875rem;
                box-sizing: border-box;
                border-radius: 3.125rem;
                border: 1px solid #009174;
                width: fit-content;
                cursor: pointer;

                .text {
                  font-size: 1.3125rem;
                  color: #0A4F88;
                  font-weight: 600;
                  font-family: PingFang SC;
                  display: block;
                }
              }
            }

            .policy-wp {
              margin-top: 5.3125rem;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .supplied-wp {
                display: flex;
                align-items: center;

                .text {
                  font-size: 1.5rem;
                  font-family: Source Han Serif SC;
                  font-weight: bold;
                  color: #000000;
                }

                .svg-icon {
                  width: 13.75rem;
                  height: 1.1875rem;
                  display: block;
                  margin-left: 0.6875rem;
                }
              }

              .text-wp {
                margin-top: 1.5rem;

                .text {
                  font-size: 1.5rem;
                  font-family: Source Han Serif SC;
                  font-weight: bold;
                  color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.is-pad {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .desc-wp {
    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .in-form {
      font-size: 1.6875rem * $mul;
    }

    .form-wp {
      .tab-wp {
        .tab-item-wp {
          .text-wp {
            .text {
              font-size: 1.5rem * $mul;
            }
          }
        }
      }

      .form-content-wp {
        .form1 {
          .form-input-wp {
            .input-line-wp {
              .input-item-wp {
                .text-wp {
                  .text {
                    font-size: 1.6875rem * $mul;
                  }
                }

                .input-wp {
                  .input {
                    font-size: 1.5rem * $mul;
                  }
                }
              }
            }

            .checkbox-line-wp {
              .checkbox-item-wp {
                .checkbox-wp {
                  .checkbox-label {
                    font-size: 1.6875rem * $mul;
                  }
                }
              }
            }

            .btn-wp {
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }
          }
        }

        .form2 {
          .form-input-wp {
            .input-line-wp {
              .input-item-wp {
                .text-wp {
                  .text {
                    font-size: 1.6875rem * $mul;
                  }
                }

                .input-wp {
                  .input {
                    font-size: 1.5rem * $mul;
                  }
                }

                .text-star {
                  font-size: 1.6875rem * $mul;
                }
              }
            }

            .checkbox-line-wp {
              .checkbox-item-wp {
                .checkbox-wp {
                  .checkbox-label {
                    font-size: 1.6875rem * $mul;
                  }
                }
              }
            }

            .btn-wp {
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }

            .policy-wp {
              .supplied-wp {
                .text {
                  font-size: 1.5rem * $mul;
                }
              }

              .text-wp {
                .text {
                  font-size: 1.5rem * $mul;
                }
              }
            }
          }
        }
      }
    }
  }
}

.is-mobile {
  $mul: 1;

  .banner-wp {
    .title-wp {
      .label-wp {
        .text {
          font-size: 5.625rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1.375rem * $mul;
        }
      }
    }
  }

  .desc-wp {
    padding: 0 10%;

    .text {
      font-size: 1.5rem * $mul;
    }

    .bold {
      font-size: 1.625rem * $mul;
    }

    .in-form {
      font-size: 1.6875rem * $mul;
    }

    .form-wp {
      .tab-wp {
        .tab-item-wp {
          .text-wp {
            .text {
              font-size: 1.125rem * $mul;
            }
          }
        }
      }

      .form-content-wp {
        .form1 {
          .form-input-wp {
            padding-left: 0;
            padding-right: 0;

            .input-line-wp {
              width: 100%;

              .input-item-wp {
                .text-wp {
                  .text {
                    font-size: 1.6875rem * $mul;
                  }
                }

                .input-wp {
                  width: 100%;
                  .input {
                    font-size: 1.5rem * $mul;
                  }
                }
              }
            }

            .fill {
              width: 100% !important;

              .input-wp {
                width: 100% !important;
              }
            }

            .checkbox-line-wp {
              .checkbox-item-wp {
                .checkbox-wp {
                  .checkbox-label {
                    font-size: 1.6875rem * $mul;
                  }
                }
              }
            }

            .btn-wp {
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }
          }
        }

        .form2 {
          .form-input-wp {
            .input-line-wp {
              width: 80%;
              flex-wrap: wrap;

              .input-item-wp {
                width: 100%;

                .text-wp {
                  margin-top: 2rem;

                  .text {
                    font-size: 1.6875rem * $mul;
                  }
                }

                .input-wp {
                  width: 100%;

                  .input {
                    font-size: 1.5rem * $mul;
                  }
                }

                .text-star {
                  font-size: 1.6875rem * $mul;
                }
              }
            }

            .line2 {
              margin-top: 0;
            }

            .checkbox-line-wp {
              .checkbox-item-wp {
                .checkbox-wp {
                  .checkbox-label {
                    font-size: 1.6875rem * $mul;
                  }
                }
              }
            }

            .btn-wp {
              margin-top: 2rem;
              .btn {
                .text {
                  font-size: 1.3125rem * $mul;
                }
              }
            }

            .policy-wp {
              .supplied-wp {
                .text {
                  font-size: 1.5rem * $mul;
                }
              }

              .text-wp {
                .text {
                  font-size: 1.5rem * $mul;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
</style>
