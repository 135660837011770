<template>
  <div>
    <div v-if="deviceType === 'pc' || deviceType === 'pad'" :class="{header: true, 'no-background': noBackground, 'theme-black': theme === 'black', 'theme-white': theme === 'white', 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad'}">
      <div class="login-wp">
        <div class="login-btn" @click="showLoginModel">
          <span class="text">{{$t('header.login')}}</span>
        </div>
        <div class="search-btn">
          <span class="text">{{$t('header.search')}}</span>
          <svg-icon icon-class="search" v-if="theme === 'black'" />
          <svg-icon icon-class="search_white" v-else />
        </div>
        <div class="lang-btn" @click="changeLanguage">
          <span class="text">{{lang}}</span>
          <svg-icon icon-class="language" v-if="theme === 'black'" />
          <svg-icon icon-class="language_white" v-else />
        </div>
      </div>
      <div class="nav-wp">
        <div class="logo-wp" @click="toIndex">
          <svg-icon icon-class="logo" v-if="theme === 'black'" />
          <svg-icon icon-class="logo_white" v-else />
        </div>
        <div class="nav-items-wp">
          <div class="nav-item-wp" @click="toIndex">
            <span class="text">{{$t('header.about')}}</span>
          </div>
          <div class="nav-item-wp" @click="showSubNav('strategies')">
            <span class="text">{{$t('header.strategies')}}</span>
            <svg-icon icon-class="arrow_type2_down" v-if="theme === 'white' && subNavName !== 'strategies'"/>
            <svg-icon icon-class="arrow_type2_up" v-if="theme === 'white' && subNavName === 'strategies'"/>
            <svg-icon icon-class="arrow_type2_down_black" v-if="theme === 'black' && subNavName !== 'strategies'"/>
            <svg-icon icon-class="arrow_type2_up_black" v-if="theme === 'black' && subNavName === 'strategies'"/>
          </div>
  <!--        <div class="nav-item-wp">-->
  <!--          <span class="text">Sustainable Value</span>-->
  <!--          <svg-icon icon-class="arrow_type2_down" />-->
  <!--        </div>-->
          <div class="nav-item-wp" @click="showSubNav('media')">
            <span class="text">{{$t('header.media')}}</span>
            <svg-icon icon-class="arrow_type2_down" v-if="theme === 'white' && subNavName !== 'media'"/>
            <svg-icon icon-class="arrow_type2_up" v-if="theme === 'white' && subNavName === 'media'"/>
            <svg-icon icon-class="arrow_type2_down_black" v-if="theme === 'black' && subNavName !== 'media'"/>
            <svg-icon icon-class="arrow_type2_up_black" v-if="theme === 'black' && subNavName === 'media'"/>
          </div>
        </div>
      </div>
      <div class="animate__animated animate__fadeIn" v-if="subNavShow">
        <div class="sub-nav-wp" v-if="subNavName === 'strategies'">
          <div class="left-wp">
            <div class="label-wp">
              <span class="text">{{$t('header.strategies')}}</span>
            </div>
            <div class="text-wp">
              <span class="text">{{$t('header.strategiesSubNavDesc')}}</span>
            </div>
            <div class="btn-wp">
              <span class="text">{{$t('header.findOutMore')}}</span>
            </div>
          </div>
          <div class="right-wp">
            <div class="nav-items-wp" v-for="(item, idx) in strategiesList" :key="item.name">
              <div :class="{'nav-item-wp': true, 'active': currentStrategiesIdx == idx}" @click="toStrategies(idx)">
                <span class="text">{{item.label}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-nav-wp media" v-if="subNavName === 'media'">
          <div class="left-wp">
            <div class="label-wp">
              <span class="text">{{$t('header.media')}}</span>
            </div>
            <div class="text-wp">
              <span class="text">{{$t('header.mediaSubNavDesc')}}</span>
            </div>
            <div class="btn-wp">
              <span class="text">{{$t('header.findOutMore')}}</span>
            </div>
          </div>
          <div class="right-wp">
            <div class="nav-items-wp" v-for="(item, idx) in mediaList" :key="item.name">
              <div :class="{'nav-item-wp': true, 'active': currentMediaIdx == idx}" @click="toMedia(idx)">
                <span class="text">{{item.label}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="deviceType === 'mobile'" :class="{'mobile-header': true, 'no-background': noBackground, 'theme-black': theme === 'black', 'theme-white': theme === 'white'}">
      <div class="circle-btn-wp" @click="toggleMobileNav">
        <div class="line-1"></div>
        <div class="line-2"></div>
      </div>
      <div class="nav-items-wp" v-if="isShowMobileNav">
        <div class="login-wp">
          <div class="login-btn" @click="showLoginModel">
            <span class="text">{{$t('header.login')}}</span>
          </div>
          <div class="search-btn">
            <span class="text">{{$t('header.search')}}</span>
          </div>
          <div class="lang-btn" @click="changeLanguage">
            <span class="text">{{lang}}</span>
            <svg-icon icon-class="language" />
          </div>
        </div>
        <div class="nav-item-wp first" @click="toIndex">
          <div class="nav-main-wp">
            <div class="text-wp">
              <span class="text">{{$t('header.about')}}</span>
            </div>
          </div>
        </div>
        <div class="nav-item-wp">
          <div class="nav-main-wp" @click="showSubNav('strategies')">
            <div class="text-wp">
              <span class="text">{{$t('header.strategies')}}</span>
            </div>
          </div>
          <div class="sub-nav-wp animate__animated animate__fadeIn" v-if="subNavShow && subNavName === 'strategies'">
            <div class="left-wp">
              <div class="label-wp">
                <span class="text">{{$t('header.strategies')}}</span>
              </div>
              <div class="text-wp">
                <span class="text">{{$t('header.strategiesSubNavDesc')}}</span>
              </div>
              <div class="btn-wp">
                <span class="text">{{$t('header.findOutMore')}}</span>
              </div>
            </div>
            <div class="right-wp">
              <div class="sub-nav-items-wp">
                <div v-for="(item, idx) in strategiesList" :key="item.name" :class="{'sub-nav-item-wp': true, 'active': currentStrategiesIdx == idx}" @click="toStrategies(idx)">
                  <span class="text">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-item-wp">
          <div class="nav-main-wp" @click="showSubNav('media')">
            <div class="text-wp">
              <span class="text">{{$t('header.media')}}</span>
            </div>
          </div>
          <div class="sub-nav-wp animate__animated animate__fadeIn" v-if="subNavShow && subNavName === 'media'">
            <div class="left-wp">
              <div class="label-wp">
                <span class="text">{{$t('header.media')}}</span>
              </div>
              <div class="text-wp">
                <span class="text">{{$t('header.mediaSubNavDesc')}}</span>
              </div>
              <div class="btn-wp">
                <span class="text">{{$t('header.findOutMore')}}</span>
              </div>
            </div>
            <div class="right-wp">
              <div class="sub-nav-items-wp" v-for="(item, idx) in mediaList" :key="item.name">
                <div :class="{'sub-nav-item-wp': true, 'active': currentMediaIdx == idx}" @click="toMedia(idx)">
                  <span class="text">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <s3-layer
        :class="{'login-model-wp': true, 'is-pc': deviceType === 'pc', 'is-pad': deviceType === 'pad', 'is-mobile': deviceType === 'mobile'}"
        v-model="loginModelShow"
        title=""
        area="87.03%"
        :btn="[]"
        :closeBtn="0"
        :shadeClose="true"
        :shade="[0.2, '#000000']"
        :resize="false"
        :isOutAnim="false"
        :scrollbar="false"
        @end="closeLoginModel">
      <div class="header-wp">
        <div class="btn-wp">
          <div class="btn" @click="closeLoginModel">
            <svg-icon icon-class="close" />
          </div>
        </div>
      </div>
      <div class="sep-h"></div>
      <div class="login-wp">
        <div class="input-wp">
          <input class="input" type="text" v-model="loginInviteCode" placeholder="Invite Code" />
        </div>
        <div class="btn-wp">
          <div class="btn">
            <div class="text">{{$t('header.login')}}</div>
          </div>
        </div>
      </div>
    </s3-layer>
  </div>
</template>

<script>
import 'animate.css';
import {mapGetters} from "vuex";

export default {
  name: "HeaderIndex",
  components: {
  },
  props: {
    noBackground: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    theme: {
      type: String,
      default: () => {
        return 'black';
      }
    }
  },
  data: function() {
    return {
      subNavShow: false,
      subNavName: '',
      currentStrategiesIdx: -1,
      currentMediaIdx: -1,
      loginModelShow: false,
      loginInviteCode: '',
      isShowMobileNav: false,
    }
  },
  created() {
  },
  computed: {
    ...mapGetters([
      'deviceType'
    ]),
    strategiesList() {
      return [
        {
          name: 'asia',
          label: this.$t('header.strategiesNavLabel1'),
          link: '/strategies/asia'
        },
        {
          name: 'opportunities',
          label: this.$t('header.strategiesNavLabel2'),
          link: '/strategies/opportunities'
        },
        {
          name: 'growth',
          label: this.$t('header.strategiesNavLabel3'),
          link: '/strategies/growth'
        },
        {
          name: 'secondaries',
          label: this.$t('header.strategiesNavLabel4'),
          link: '/strategies/secondaries'
        },
        {
          name: 'infrastructure',
          label: this.$t('header.strategiesNavLabel5'),
          link: '/strategies/infrastructure'
        }
      ];
    },
    mediaList() {
      return [
        {
          name: 'mediaContacts',
          label: this.$t('header.mediaNavLabel1'),
          link: '/media/contacts'
        },
        {
          name: 'mediaEmailAlert',
          label: this.$t('header.mediaNavLabel2'),
          link: '/media/email_alert'
        }
      ]
    }
  },
  methods: {
    showSubNav(name) {
      if (name !== this.subNavName) {
        this.subNavName = name;
        if (!this.subNavShow) {
          this.subNavShow = true;
        }
      } else {
        if (this.subNavShow) {
          this.subNavShow = false;
          this.subNavName = '';
        } else {
          this.subNavShow = true;
          this.subNavName = name;
        }
      }
    },

    toStrategies(idx) {
      if (this.currentStrategiesIdx !== idx) {
        this.currentStrategiesIdx = idx;
        this.$router.push({
          path: this.strategiesList[this.currentStrategiesIdx].link
        })
        this.toggleMobileNav();
      }
    },

    toMedia(idx) {
      if (this.currentMediaIdx !== idx) {
        this.currentMediaIdx = idx;
        this.$router.push({
          path: this.mediaList[this.currentMediaIdx].link
        })
        this.toggleMobileNav();
      }
    },

    toIndex() {
      this.$router.push({
        path: '/'
      })
      if (this.deviceType === 'mobile') {
        this.toggleMobileNav();
      }
    },

    showLoginModel() {
      if (this.deviceType === 'mobile') {
        this.toggleMobileNav()
      }
      this.loginModelShow = true;
    },

    closeLoginModel() {
      this.loginModelShow = false;
    },

    toggleMobileNav() {
      if (this.isShowMobileNav) {
        this.isShowMobileNav = false;
        this.subNavShow = false;
        this.subNavName = '';
      } else {
        this.isShowMobileNav = true;
      }
    }
  }
}
</script>
<script setup>
import { ref } from 'vue'
import {useI18n} from 'vue-i18n'
const I18n = useI18n();

const lang = ref('En')
function changeLanguage() {
  let curLang = I18n.locale.value;
  if (curLang === 'en') {
    I18n.locale.value = 'indonesian'
    lang.value = 'Indonesian'
  } else {
    I18n.locale.value = 'en'
    lang.value = 'En'
  }
}
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 1.875rem;
  position: relative;
  z-index: 100;

  .login-wp {
    padding: 2rem 6.25rem 0 6.25rem;
    display: flex;
    align-items: center;

    .login-btn {
      width: fit-content;
      width: -moz-fit-content;
      margin-left: auto;
      height: 1.375rem;
      display: flex;
      align-items: center;

      .text {
        color: #000000;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.375rem;
        cursor: pointer;
      }
    }

    .search-btn {
      width: fit-content;
      width: -moz-fit-content;
      height: 1.375rem;
      margin-left: 4.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .text {
        color: #000000;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.375rem;
        cursor: pointer;
      }

      .svg-icon {
        margin-left: 0.4375rem;
        display: block;
        width: 0.75rem;
        height: 0.6875rem;
      }
    }

    .lang-btn {
      width: fit-content;
      width: -moz-fit-content;
      height: 1.375rem;
      margin-left: 4.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .text {
        color: #000000;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.375rem;
        cursor: pointer;
      }

      .svg-icon {
        margin-left: 0.4375rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .nav-wp {
    margin-top: 3.5rem;
    padding: 0 7.875rem 0 6.25rem;
    display: flex;
    justify-content: space-between;

    .logo-wp {

      .svg-icon {
        width: 6.0625rem;
        height: 2.25rem;
        display: block;
      }
    }

    .nav-items-wp {
      display: flex;
      align-items: center;

      .nav-item-wp {
        cursor: pointer;
        margin-left: 3.75rem;
        height: 1.375rem;
        display: flex;
        align-items: center;

        .text {
          color: #000000;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.375rem;
        }

        .svg-icon {
          width: 0.5625rem;
          height: 0.375rem;
          display: block;
          margin-left: 0.4375rem;
        }
      }
    }
  }

  .sub-nav-wp {
    background: #FFFFFF;
    padding: 4.25rem 0 4.5625rem 0;
    position: absolute;
    top: 11rem;
    left: 0;
    width: 100%;
    border-top: 1px solid #D6DFE8;
    display: flex;

    .left-wp {
      width: 39.27%;
      flex-shrink: 0;
      padding: 0 9.9375rem 11.75rem 14.375rem;
      box-sizing: border-box;

      .label-wp {
        margin-top: 1.375rem;

        .text {
          font-size: 2.5rem;
          font-weight: bold;
          font-family: Source Han Serif SC;
          color: #0A4F88;
        }
      }

      .text-wp {
        margin-top: 1.875rem;

        .text {
          font-size: 1rem;
          font-weight: 400;
          font-family: PingFang SC;
          color: #000000;
        }
      }

      .btn-wp {
        margin-top: 5rem;
        padding: 1.5rem 4.75rem;
        box-sizing: border-box;
        border: 1px solid #000000;
        border-radius: 2.5625rem;
        width: fit-content;

        .text {
          font-size: 1rem;
          font-weight: 400;
          font-family: PingFang SC;
          color: #000000;
          display: block;
        }
      }
    }

    .right-wp {
      flex-grow: 1;
      border-left: 1px solid #F3F3F3;

      .nav-items-wp {
        .nav-item-wp {
          height: 2.3125rem;
          padding-left: 4.875rem;
          margin-bottom: 2.375rem;
          cursor: pointer;

          .text {
            font-size: 1.125rem;
            font-weight: 400;
            font-family: PingFang SC;
            color: #000000;
            line-height: 1.2;
          }
        }

        .active {
          border-left: 1px solid #0A4F88;
        }
      }
    }
  }

  .media {
    .left-wp {
      .btn-wp {
        border-color: #009174;
      }
    }
  }
}

.is-pad {
  $mul: 1;

  .login-wp {
    .login-btn {
      .text {
        font-size: 1rem * $mul;
      }
    }

    .search-btn {
      .text {
        font-size: 1rem * $mul;
      }
    }
  }

  .nav-wp {
    .nav-items-wp {
      .nav-item-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }
    }
  }

  .sub-nav-wp {
    .left-wp {
      .label-wp {
        .text {
          font-size: 2.5rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }

      .btn-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }
    }

    .right-wp {
      .nav-items-wp {
        .nav-item-wp {
          .text {
            font-size: 1.125rem * $mul;
          }
        }
      }
    }
  }
}

.mobile-header {
  position: relative;
  z-index: 100;

  .circle-btn-wp {
    width: 10vw;
    height: 10vw;
    background: #FFFFFF;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    border-radius: 4.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .line-1 {
      width: 60%;
      height: 1.2vw;
      background: #333333;
      border-radius: 1vw;
    }

    .line-2 {
      width: 60%;
      height: 1.2vw;
      background: #333333;
      border-radius: 1vw;
      margin-top: 1vw;
    }
  }

  .nav-items-wp {

    $mul: 1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #F9F9F9;
    padding: 0 2.5rem;
    box-sizing: border-box;

    .login-wp {
      padding: 4rem 0 5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 70%;

      .login-btn {
        width: fit-content;
        width: -moz-fit-content;
        display: flex;
        align-items: center;
        border: 1px solid #C3C3C3;
        padding: 1.25rem 3.75rem;
        box-sizing: border-box;
        border-radius: 1.25rem;
        margin-top: 1rem;

        .text {
          color: #000000;
          font-weight: 500;
          font-size: 1.875rem * $mul;
          cursor: pointer;
          display: block;
        }
      }

      .search-btn {
        width: fit-content;
        width: -moz-fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #C3C3C3;
        padding: 1.25rem 3.75rem;
        box-sizing: border-box;
        border-radius: 1.25rem;
        margin-top: 1rem;

        .text {
          color: #000000;
          font-weight: 500;
          font-size: 1.875rem * $mul;
          cursor: pointer;
          display: block;
        }
      }

      .lang-btn {
        width: fit-content;
        width: -moz-fit-content;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #C3C3C3;
        padding: 1.25rem 3.75rem;
        box-sizing: border-box;
        border-radius: 1.25rem;
        margin-top: 1rem;

        .text {
          color: #000000;
          font-weight: 500;
          font-size: 1.875rem * $mul;
          cursor: pointer;
          display: block;
        }

        .svg-icon {
          margin-left: 0.5rem;
        }
      }
    }

    .nav-item-wp {
      border-top: 1px solid #E9E9E9;

      &.first {
        //border-top: none;
      }

      .nav-main-wp {
        .text-wp {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5rem 0;
          box-sizing: border-box;

          .text {
            font-size: 4rem;
            display: block;
          }
        }
      }

      .sub-nav-wp {
        background: #FFFFFF;
        padding: 3rem 0 3.3125rem 0;
        width: 100%;
        border-top: 1px solid #D6DFE8;
        display: flex;

        .left-wp {
          width: 45%;
          flex-shrink: 0;
          padding: 0 3.75rem 3.75rem 3.75rem;
          box-sizing: border-box;

          .label-wp {
            margin-top: 0.625rem;

            .text {
              font-size: 2.5rem * $mul;
              font-weight: bold;
              font-family: Source Han Serif SC;
              color: #0A4F88;
              display: block;
            }
          }

          .text-wp {
            margin-top: 0.625rem;
            line-height: 0;

            .text {
              font-size: 1rem * $mul;
              font-weight: 400;
              font-family: PingFang SC;
              color: #000000;
              line-height: 1.2;
              display: block;
            }
          }

          .btn-wp {
            margin-top: 3.75rem;
            padding: 1.5rem 4.75rem;
            box-sizing: border-box;
            border: 1px solid #000000;
            border-radius: 1.25rem;
            width: fit-content;

            .text {
              font-size: 1rem * $mul;
              font-weight: 400;
              font-family: PingFang SC;
              color: #000000;
              display: block;
            }
          }
        }

        .right-wp {
          flex-grow: 1;
          border-left: 1px solid #F3F3F3;

          .sub-nav-items-wp {
            .sub-nav-item-wp {
              height: 2.3125rem;
              padding-left: 4.875rem;
              margin-bottom: 2.375rem;
              cursor: pointer;

              .text {
                font-size: 1.125rem * $mul;
                font-weight: 400;
                font-family: PingFang SC;
                color: #000000;
                line-height: 1.2;
                display: block;
              }
            }

            .active {
              border-left: 1px solid #0A4F88;
            }
          }
        }
      }
    }
  }
}

.is-mobile {
  $mul: 1;

  .login-wp {
    .login-btn {
      .text {
        font-size: 1rem * $mul;
      }
    }

    .search-btn {
      .text {
        font-size: 1rem * $mul;
      }
    }

    .lang-btn {
      .text {
        font-size: 1rem * $mul;
      }
    }
  }

  .nav-wp {
    .nav-items-wp {
      .nav-item-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }
    }
  }

  .sub-nav-wp {
    .left-wp {
      .label-wp {
        .text {
          font-size: 2.5rem * $mul;
        }
      }

      .text-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }

      .btn-wp {
        .text {
          font-size: 1rem * $mul;
        }
      }
    }

    .right-wp {
      .sub-nav-items-wp {
        .sub-nav-item-wp {
          height: auto !important;

          .text {
            font-size: 1.125rem * $mul;
            line-height: 1.2 !important;
          }
        }
      }
    }
  }
}

.header.theme-white {
  .login-wp {
    .text {
      color: #FFFFFF !important;
    }
  }

  .nav-wp {
    .text {
      color: #FFFFFF !important;
    }
  }
}

.no-background {
  background: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
</style>
<style lang="scss">
.login-model-wp {
  border-radius: 1.75rem !important;
  overflow: hidden !important;
  background: #FFFFFF !important;
  box-shadow: none !important;

  .layui-layer-content {
    padding: 0 !important;
    margin: 0 !important;
  }
  .layui-layer-btn {
    display: none;
  }

  .header-wp {
    padding: 2.375rem 0 2.6875rem 0;

    .btn-wp {
      margin-left: auto;
      display: flex;
      margin-right: 4.9375rem;
      width: fit-content;

      .btn {
        border-radius: 6.25rem;
        border: 1px solid #B4C8D7;
        box-sizing: border-box;
        padding: 1.125rem 2rem;


        .svg-icon {
          width: 1rem;
          height: 1rem;
          display: block;
        }
      }
    }
  }


  .sep-h {
    border-top: 1px solid #E0E0E0;
  }

  .login-wp {
    margin-top: 4.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 9.625rem;
    border-radius: 12.5rem;
    flex-direction: column;
    width: 100%;

    .input-wp {
      width: 80%;
      height: 7.5rem;
      background: #F3F3F3;
      border-radius: 12.5rem;
      padding: 2.75rem 5rem;
      box-sizing: border-box;

      .input {
        background: none;
        height: 2.0625rem;
        border: none;
        font-size: 1.5rem;
        color: #333333;
      }

      .input::placeholder {
        color: #0A4F88;
      }

      .input:focus {
        border: none;
        outline: none;
      }
    }

    .btn-wp {
      margin-top: 7.125rem;
      width: fit-content;

      .btn {
        padding: 1.125rem 6.75rem;
        box-sizing: border-box;
        border: 1px solid #009174;
        border-radius: 6.25rem;

        .text {
          font-size: 1.5625rem;
          color: #000000;
          font-weight: 400;
          font-family: PingFang SC;
        }
      }
    }
  }
}
.login-model-wp.is-pad {
  $mul: 1;

  .login-wp {
    .input-wp {
      .input {
        font-size: 1.5rem * $mul;
      }
    }

    .btn-wp {
      .btn {
        .text {
          font-size: 1.5625rem * $mul;
        }
      }
    }
  }
}

.login-model-wp.is-mobile {
  $mul: 1;

  .login-wp {
    .input-wp {
      .input {
        font-size: 1.5rem * $mul;
      }
    }

    .btn-wp {
      .btn {
        .text {
          font-size: 1.5625rem * $mul;
        }
      }
    }
  }
}
</style>
