import { createApp } from 'vue'
import App from './App.vue'
import registerSvgIcon from "./icons";
import { s3Layer } from 'vue3-layer';
import 'vue3-layer/dist/s3Layer.css';
import router from './router'
import store from "@/store"
import { createMetaManager} from 'vue-meta'
import i18n from './language'

const app = createApp(App)
registerSvgIcon(app)
app.component("s3-layer", s3Layer)
app.use(router)
app.use(store)
app.use(createMetaManager(false, {
    meta: { tag: 'meta', nameless: true }
}))
app.use(i18n)

app.mount('#app')
